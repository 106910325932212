import React, { useState } from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { Typography, Select as MUISelect, Grid } from "@mui/material";
import Button from "../../components/shared/customBtn";
import classes from "./management.module.scss";
import DraggableTable from "../../components/shared/dragTable";
import deleteicon from "../../assets/images/deleteicon.svg";
import Delete from "../../components/shared/deleteModal";
import CustomInput from "../../components/shared/customInput";

const columnsChoices = [
  { key: "position", label: "Position" },
  { key: "category", label: "Category" },
];
const initialRowsChoices = [
  {
    id: "1",
    position: "01",
    category: "Chef’s Specialties",
  },
  {
    id: "2",
    position: "02",
    category: "Grilled/Flamed Chicken Meals",
  },
  {
    id: "3",
    position: "03",
    category: "Prawn & Fish Dishes",
  },
  {
    id: "4",
    position: "04",
    category: "Traditional Desserts",
  },
];
const CreateChoice = ({ CategoryId, onSubmit, headTitle }) => {
  const validationSchema = Yup.object().shape({
    categoryName: Yup.string().required("Category Name is required"),
    categoryType: Yup.string().required("Category Type is required"),
    categoryDesc: Yup.string().required("Category Description is required"),
  });
  const [rowsChoices, setRowsChoices] = useState(initialRowsChoices);

  console.log("CategoryID", CategoryId);

  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDelete = (id) => {
    setDeleteId(id);

    setIsDelete(true);
    setOpenDelete(true);
  };
  console.log("deleteId", deleteId);
  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
    setConfirmDelete(true);
  };

  return (
    <Formik
      initialValues={
        CategoryId
          ? {
              categoryName: "Mutton Karahi",
              categoryType: "Chef’s Specialties",
              categoryDesc: "Slow cooked with tomatoes",
            }
          : {
              categoryName: "",
              categoryType: "",
              categoryDesc: "",
            }
      }
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
      }) => (
        <Form className={classes.CreateCategoryForm} onSubmit={handleSubmit}>
          <Typography className={classes.title}>{headTitle}</Typography>
          <hr />
          <div className={classes.header}>
            <h6> Catergory Details</h6>
          </div>

          <Grid container spacing={1}>
            <Grid item lg={12}>
              <CustomInput
                label="Category Name"
                type="text"
                name="categoryName"
                value={values.categoryName}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Enter Category Name "
                touched={touched.categoryName}
                errors={errors.categoryName}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ paddingBottom: "20px" }}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <CustomInput
                label="Category Type"
                type="select"
                name="categoryType"
                value={values.categoryType}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Select option"
                touched={touched.categoryType}
                errors={errors.categoryType}
                options={[
                  "Chef’s Specialties",
                  "Grilled/Flamed Chicken Meals",
                  "Prawn & Fish Dishes",
                  "Traditional Desserts",
                ]}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <CustomInput
                label="Category Description"
                type="text"
                name="categoryDesc"
                value={values.categoryDesc}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Enter Category Description "
                touched={touched.categoryDesc}
                errors={errors.categoryDesc}
              />
            </Grid>
          </Grid>

          <hr />

          <div className={classes.content}>
            <h6>Sort Order</h6>
            <p>
              Sort the order in which categories will display to your customers.
            </p>
          </div>

          <div className="createTableStyle">
            <DraggableTable
              columns={columnsChoices}
              rows={rowsChoices}
              setRows={setRowsChoices} 
              choicesTable
            />
          </div>
          <hr />

          {deleteId && (
            <Delete
              isOpen={openDelete}
              onClose={handleClose}
              handleConfirmDelete={handleConfirmDelete}
              isDelete={isDelete}
              confirmDelete={confirmDelete}
              name="Category"
            />
          )}

          <div className={classes.btncontainer}>
            {CategoryId ? (
              <>
                <Button
                  fontsize="1.375rem"
                  customWidth="432px"
                  customClass={classes.delBtn}
                  onClick={() => handleDelete(CategoryId)}
                >
                  <img src={deleteicon} alt="" />
                  Delete Category
                </Button>

                <Button
                  type="submit"
                  fontsize="1.375rem"
                  customWidth="432px"
                  customClass={classes.saveBtn}
                  shadowOrange
                >
                  Save Changes!
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="submit"
                  fontsize="1.375rem"
                  customWidth="432px"
                  customClass={classes.createBtn}
                  shadowOrange
                >
                  Create Category
                </Button>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateChoice;
