import React, { useState, useEffect } from "react";
import classes from "./manageRole.module.scss";
import { Checkbox, Switch } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Dashboard = ({ currentDashboard }) => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [searchTerm, setSearchTerm] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [permissionsDeep, setPermissionssDeep] = useState([]);

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
  };

  useEffect(() => {
    if (searchTerm.length > 2) {
      const filteredCustomers = permissionsDeep.filter((customer) =>
        customer?.name?.toLowerCase().includes(searchTerm)
      );
      setPermissions(filteredCustomers);
    } else {
      setPermissions(permissionsDeep);
    }
  }, [searchTerm, permissionsDeep]);

  return (
    <div className={classes.dashboardWrapper}>
      <div className={classes.switchHolder}>
        <span>Select All Group Permissions</span>
        <Switch {...label} />
      </div>
      <div className={classes.dashboardHolder}>
        <div className={classes.dashboardHeader}>
          <div className={classes.switchHolder}>
            <span>Select All</span>
            <Switch {...label} />
          </div>
          <div className={classes.searchInputContainer}>
            <SearchIcon className={classes.searchIcon} />
            <input
              type="text"
              placeholder="Search in Permissions"
              value={searchTerm}
              onChange={handleSearch}
              className={classes.searchInput}
            />
          </div>
        </div>
        <div className={classes.checkboxWrap}>
          <Checkbox />
          <span>{`${currentDashboard}.nav`}</span>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
