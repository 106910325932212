import React, { useState, useEffect } from "react";
import ReusableTable from "../../components/shared/resuabletable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import classes from "./reports.module.scss";
import "./reportsBuiltin.scss";
import jsonData from "./reportData.json";
import emilia from "../../assets/images/sajiha/emilia.png";
import james from "../../assets/images/sajiha/james.png";
import ReportModal from "./reportModel";
import TableFilter from "../../components/shared/tableFilter";

const imageMap = {
  emilia: emilia,
  james: james,
};

const Reports = () => {
  const [customers, setCustomers] = useState([]);
  const [customersDeep, setCustomersDeep] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [viewDetail, setViewDetail] = useState(null);
  const [error, setError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const handleData = async () => {
      try {
        // Use the imported JSON data instead of fetching from an API
        setCustomers(jsonData);
        setCustomersDeep(jsonData);
      } catch (error) {
        setError("Failed to load data. Please try again later.");
      }
    };
    handleData();
  }, []);

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
  };

  useEffect(() => {
    if (searchTerm.length > 2) {
      const filteredCustomers = customersDeep.filter((customer) =>
        customer?.name?.toLowerCase().includes(searchTerm)
      );
      setCustomers(filteredCustomers);
    } else {
      setCustomers(customersDeep);
    }
  }, [searchTerm, customersDeep]);

  const columns = [
    { label: "Order Number", field: "orderNumber" },
    { label: "Transaction Number", field: "transactionNumber" },
    {
      label: "Customer",
      field: "name",
      render: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={imageMap[row.imageKey]}
              alt={row.name}
              style={{
                width: 30,
                height: 30,
                borderRadius: "50%",
                marginRight: 10,
              }}
            />
            {row.name}
          </div>
        );
      },
    },
    { label: "Total Item", field: "totalItems" },
    { label: "by Date & Time", field: "dateTime" },
    { label: "Total Sale", field: "totalSale" },
  ];

  const handleClose = () => {
    setOpenDelete(false);
  };

  const handleReport = (id) => {
    setOpenDelete(true);
    setViewDetail(id);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleExport = () => {
    const input = document.getElementById("tableToExport");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("landscape");
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("report.pdf");
    });
  };

  return (
    <div className={classes.table_section}>
      <div className={classes.headerContainer}>
        <TableFilter
          heading="Reports"
          searchPlaceholder="Search in Reports"
          handleSearch={handleSearch}
          exportBtn
          handleExport={handleExport}
          buttonTextLeft="Export"
          datePicker
        />
      </div>
      <Box className={`${classes.Reportstabs} ReporsTabBuiltin`}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="report tabs"
          variant="fullWidth"
          classes={{ indicator: classes.tabIndicator }}
        >
          <Tab
            label="Daily Report"
            classes={{ selected: classes.tabSelected }}
          />
          <Tab
            label="Weekly Report"
            classes={{ selected: classes.tabSelected }}
          />
        </Tabs>
      </Box>
      <div id="tableToExport">
        {tabIndex === 0 && (
          <ReusableTable
            columns={columns}
            data={customers}
            dataDeep={customersDeep}
            handleReport={handleReport}
            searchTerm={searchTerm}
            pagination
            rowPerPage={10}
            actionButton
          />
        )}
        {tabIndex === 1 && (
          <ReusableTable
            columns={columns}
            data={customers}
            dataDeep={customersDeep}
            handleReport={handleReport}
            searchTerm={searchTerm}
            pagination
            rowPerPage={10}
            actionButton
          />
        )}
      </div>
      {viewDetail && <ReportModal isOpen={openDelete} onClose={handleClose} />}
    </div>
  );
};

export default Reports;
