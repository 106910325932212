import React, { useState } from "react";
import BasicModal from "../basicmodal/index.js";
import Button from "../customBtn";
import classes from "./deleteModal.module.scss";
import recycle from "../../../assets/images/delete_icon.gif";
import success from "../../../assets/images/success_icon.gif";

const Delete = ({
  isOpen,
  onClose,
  handleConfirmDelete,
  confirmDelete,
  isDelete,
  name,
}) => {
  return (
    <>
      <BasicModal
        isOpen={isOpen}
        closeModal={onClose}
        customWidth="580px"
        customClass={classes.deleteStyle}
      >
        {isDelete && (
          <div className={classes.modalContent}>
            <img src={recycle} className={classes.recycle} alt="recyclebin" />
            <h1 className={classes.heading}>
              Are you sure you want to Delete <br /> this {name}?
            </h1>
            <Button
              onClick={handleConfirmDelete}
              customWidth="350px"
              fontsize="1rem"
            >
              Yes, Delete
            </Button>
          </div>
        )}
        {confirmDelete && (
          <div className={classes.modalContent}>
            <img src={success} className={classes.success} alt="success" />
            <h2 className={classes.heading}> {name} Deleted successfully!</h2>
          </div>
        )}
      </BasicModal>
    </>
  );
};

export default Delete;
