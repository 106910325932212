import React, { useState, useEffect, useRef } from "react";
import ReusableTable from "../../components/shared/resuabletable";
import Delete from "../../components/shared/deleteModal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import classes from "./management.module.scss";
import "./managementBuiltIn.scss";
import Button from "../../components/shared/customBtn";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BasicModal from "../../components/shared/basicmodal";
import TableFilter from "../../components/shared/tableFilter";
import NewMenu from "./AddNewMenu";
import NewItem from "./AddNewItem";
import CreateCategory from "./CreateCategory";
import CreateChoiceGroup from "./CreateChoiceGroup";
import CopyStore from "./copyStore";
import DraggableTable from "../../components/shared/dragTable";
import bbq from "../../assets/images/bbq.png";
import quaterchicken from "../../assets/images/quaterchicken.png";
import kabab from "../../assets/images/kabab.png";
import platter from "../../assets/images/platter.png";
import steak from "../../assets/images/steak.png";
import caretDown from "../../assets/images/caretWhite.svg";
import menuIcon from "../../assets/images/menuIcon.png";
import categoryIcon from "../../assets/images/categoryIcon.png";
import itemIcon from "../../assets/images/itemIcon.png";
import editIcon from "../../assets/images/editIcon.svg";
import copyIcon from "../../assets/images/copyIcon.svg";
import CustomLoader from "../../components/shared/customLoader";
import { toast } from "react-toastify";

import {
  Select,
  MenuItem,
  Radio,
  FormControl,
  InputLabel,
  ListItemText,
} from "@mui/material";
import { FamilyRestroomTwoTone } from "@mui/icons-material";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const initialRows = [
  {
    id: "1",
    item: "Chicken B.B.Q",
    description: "lorem ipsum is a simply dummy...",
    variation: "Reg ($150), Med ($180)",
    choiceGroup: "Choices of Sauce",
    status: "Available",
    offer: "Active",
    price: "$254",
    discountPrice: "$264",
    category: "Chef’s Specialties",
    menu: "Main Menu",
    imgName: bbq,
  },
  {
    id: "2",
    item: "Quarter Chicken",
    description: "lorem ipsum is a simply dummy...",
    variation: "Reg ($150), Med ($180)",
    choiceGroup: "Choices of Sauce",
    status: "Out of Stock",
    offer: "Inactive",
    price: "$70.45",
    category: "Grilled/Flamed Chicken Meals",
    menu: "Breakfast Menu",
    imgName: quaterchicken,
  },
  {
    id: "3",
    item: "Chicken Seekh Kabab",
    description: "lorem ipsum is a simply dummy...",
    variation: "Reg ($150), Med ($180)",
    choiceGroup: "Choices of Sauce",
    status: "Available",
    offer: "Active",
    price: "$254",
    discountPrice: "$264",
    category: "Prawn & Fish Dishes",
    menu: "Lunch Menu",
    imgName: kabab,
  },
  {
    id: "4",
    item: "Chicken B.B.Q Platter",
    description: "lorem ipsum is a simply dummy...",
    variation: "Reg ($150), Med ($180)",
    choiceGroup: "Choices of Sauce",
    status: "Out of Stock",
    offer: "Inactive",
    price: "$70.45",
    category: "Traditional Desserts",
    menu: "Dinner Menu",
    imgName: platter,
  },
  {
    id: "5",
    item: "Chicken B.B.Q",
    description: "lorem ipsum is a simply dummy...",
    variation: "Reg ($150), Med ($180)",
    choiceGroup: "Choices of Sauce",
    status: "Available",
    offer: "Active",
    price: "$254",
    discountPrice: "$264",
    category: "Chef’s Specialties",
    menu: "Main Menu",
    imgName: steak,
  },
  {
    id: "6",
    item: "Chicken B.B.Q",
    description: "lorem ipsum is a simply dummy...",
    variation: "Reg ($150), Med ($180)",
    choiceGroup: "Choices of Sauce",
    status: "Out of Stock",
    offer: "Inactive",
    price: "$70.45",
    category: "Traditional Desserts",
    menu: "Lunch Menu",
    imgName: bbq,
  },
];

const columns = [
  { key: "item", label: "Items" },
  { key: "description", label: "Description" },
  { key: "variation", label: "Variation" },
  { key: "choiceGroup", label: "Choice group" },
  { key: "status", label: "Status" },
  { key: "offer", label: "Offer" },
  { key: "price", label: "Price" },
];
const columnsChoices = [
  { key: "choicesGroups", label: "Choices Groups" },
  { key: "availableChoice", label: "Available Choices" },
  { key: "unavailableChoices", label: "Unavailable Choices" },
];
const initialRowsChoices = [
  {
    id: "1",
    choicesGroups: "Choice of Sauce",
    availableChoice: "Sweet Chilli, Mayo, Cheese, Honey Mustard",
    unavailableChoices: "BBQ sauce",
    choiceGroupText: "Required (Selected 1), 5 Choices",
  },
  {
    id: "2",
    choicesGroups: "Choice of Coffees",
    availableChoice: "Hot Tea, Iced Tea, Hot Coffee, Iced Coffee",
    unavailableChoices: "BBQ sauce",
    choiceGroupText: "Required (Selected 1), 5 Choices",
  },
  {
    id: "3",
    choicesGroups: "Choice of Drinks",
    availableChoice:
      "Pepsi Cola 500ml, Cocoa Cola 500 ml, Sprite 300ml, Fanta 300ml",
    unavailableChoices: "BBQ sauce",
    choiceGroupText: "Required (Selected 1), 5 Choices",
  },
];

const Management = () => {
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [customersDeep, setCustomersDeep] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [expanded, setExpanded] = useState("panel1");
  //delete modal states
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  //add item
  const [addItem, setAddItem] = useState(false);
  const [addChoiceGroup, setAddChoiceGroup] = useState(false);
  //add category
  const [addCategory, setAddCategory] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [editCategoryID, setEditCategoryID] = useState();
  //add menu
  const [addMenu, setAddMenu] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [editMenuData, setEditMenuData] = useState();
  const [editMenuDataSend, setEditMenuDataSend] = useState();
  const [showMenuDrop, setShowMenuDrop] = useState(false);
  const [localMenuData, setLocalMenuData] = useState();
  const [selectedValue, setSelectedValue] = useState("Main Menu");

  const [rowsChoices, setRowsChoices] = useState(initialRowsChoices);
  const [rows, setRows] = useState(initialRows);
  const [arrayNew, setArrayNew] = useState([]);

  //copystore
  const [copystore, setCopystore] = useState(false);

  console.log("editMenuDataeditMenuData,", editMenuData);
  console.log(
    "editMenuDataSendeditMenuDataSendeditMenuDataSend,",
    editMenuDataSend
  );
  const ref = useRef(null);
  // const selectedSingleMenuArray = localMenuData?.filter((item) =>
  //   item?.menuTitle?.includes(selectedValue)
  // );
  // add menu fucntionality

  useEffect(() => {
    const getLocaldataMenu = localStorage.getItem("menuData");
    const parseGetLocaldataMenu = getLocaldataMenu
      ? JSON.parse(getLocaldataMenu)
      : [];
    setLocalMenuData(parseGetLocaldataMenu);
  }, []);

  const handleAddMenuForm = (values, { resetForm }) => {
    let menuObject = {
      ...values,
      availability: selectedDays,
      uuid: Math.random(),
    };

    // Update arrayNew state
    const updatedArrayNew = [...arrayNew, menuObject];
    setArrayNew(updatedArrayNew);

    // Update localMenuData state and local storage
    const updatedLocalMenuData = [...localMenuData, menuObject];

    localStorage.setItem("menuData", JSON.stringify(updatedLocalMenuData));
    setLocalMenuData(updatedLocalMenuData);
    resetForm({ values: "" });
    setSelectedDays("");
    setAddMenu(false);
  };
  const handleUpdateMenuForm = (values, { resetForm }) => {
    resetForm({ values: "" });
    const updatedLocalMenuData = localMenuData.map((item) =>
      item.uuid === editMenuDataSend.uuid
        ? {
            ...item,
            ...values,
            availability: selectedDays,
            uuid: editMenuDataSend.uuid,
          }
        : item
    );
    const foundItem = updatedLocalMenuData.find(
      (item) => item.uuid === editMenuDataSend.uuid
    );

    localStorage.setItem("menuData", JSON.stringify(updatedLocalMenuData));

    setSelectedValue(foundItem.menuTitle);
    setLocalMenuData(updatedLocalMenuData);
    setEditMenuData(foundItem);
    setSelectedDays(foundItem?.availability);
    setAddMenu(false);
    // setSelectedDays("");
  };

  useEffect(() => {
    const selectedSingleMenuArray = localMenuData?.filter((item) =>
      item?.menuTitle?.includes(localMenuData[0]?.menuTitle)
    );
    setEditMenuData(selectedSingleMenuArray);
  }, [localMenuData]);

  // add menu fucntionality end
  const handleChangedrop = (event) => {
    const selectedItem = localMenuData?.filter((item) =>
      item?.menuTitle?.includes(event.target.value)
    );
    setSelectedValue(event.target.value);
    setEditMenuData(selectedItem);
  };

  useEffect(() => {
    const handleData = async () => {
      try {
        const rest = await fetch(
          "https://65db5d083ea883a1529197e5.mockapi.io/Crud"
        );
        const data = await rest.json();
        setCustomers(data);
        setCustomersDeep(data);
      } catch (error) {
        setError("Failed to load data. Please try again later.");
      }
    };
    handleData();
  }, []);

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
  };

  useEffect(() => {
    if (searchTerm.length > 2) {
      const filteredCustomers = customersDeep.filter((customer) =>
        customer?.name?.toLowerCase().includes(searchTerm)
      );
      setCustomers(filteredCustomers);
    } else {
      setCustomers(customersDeep);
    }
  }, [searchTerm, customersDeep]);

  const handleDelete = (id) => {
    setOpenDelete(true);
    setIsDelete(true);
    setDeleteId(id);
  };
  const [getCatogoryEdit, setGetCatogoryEdit] = useState();

  const handleEdit = (data) => {
    setAddItem(true);
    setGetCatogoryEdit(data);
    // setEditUser(true);
  };

  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
  };
  const handleConfirmDelete = () => {
    setIsDelete(false);
    setConfirmDelete(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle dropdown state
  };

  const handleRoleClose = () => {
    setAddMenu(false);
    setAddItem(false);
    setAddChoiceGroup(false);
    setAddCategory(false);
    setEditCategory(false);
    setEditMenuDataSend(null);
    setSelectedDays([]);
    setGetCatogoryEdit("");
    setCopystore(false);
  };

  const handleAddMenu = () => {
    setShowMenuDrop((prev) => !prev);
  };
  const handleOpenMenu = () => {
    setAddMenu(true);
  };

  const handleOpenItem = () => {
    setAddItem(true);
  };

  const handleUpdateItemForm = () => {
    setAddItem(false);
  };

  const handleOpenCategory = () => {
    setAddCategory(true);
  };

  const handleUpdateCategoryForm = () => {
    setAddCategory(false);
  };

  const handleChoiceGroup = () => {
    setAddChoiceGroup(true);
  };

  const handleUpdateChoiceForm = async (values) => {
    console.log("nnnnnnnn", values);
    setAddChoiceGroup(false);
  };

  const handleEditCategory = (id) => {
    setEditCategory(true);
    setEditCategoryID(id);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowMenuDrop(false);
    }
  };
  console.log("getCatogoryEdit", getCatogoryEdit);

  const handleCopyStore = () => {
    setCopystore(true);
  };
  const handleUpdateCopy = async (values) => {
    console.log("nnnnnnnn", values);
    setCopystore(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAddUser = (values) => {
    console.log("Form values:", values);
  };

  const handleEditMenu = () => {
    console.log("data", editMenuData);
    const menuobject = editMenuData.reduce((acc, item) => {
      return item;
    }, {});
    setEditMenuDataSend(menuobject);
    setSelectedDays(menuobject?.availability);
    setAddMenu(true);
  };

  useEffect(() => {
    toast.info("Wow so easy info!");
    // Simulate an async operation
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <CustomLoader />;
  }

  return (
    <div className={classes.mainSectionManagement}>
      <div className={classes.headerContainerMenuManagement}>
        <h1>Menu Management</h1>
        <p>Make changes to your menu items, prices and photos easily!</p>
      </div>
      <Box className={`MenuBuiltin`}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="report tabs"
          variant="fullWidth"
          classes={{ indicator: classes.tabIndicator }}
        >
          <Tab label="Overview" classes={{ selected: classes.tabSelected }} />
          <Tab
            label="Choice Group"
            classes={{ selected: classes.tabSelected }}
          />
        </Tabs>
        <Button
          outlineBlack
          customClass={classes.copyBtn}
          onClick={handleCopyStore}
        >
          <img src={copyIcon} alt="" /> Copy to Stores
        </Button>
        {copystore && (
          <BasicModal
            isOpen={copystore}
            closeModal={handleRoleClose}
            customWidth="994px"
          >
            <CopyStore
              onSubmit={handleUpdateCopy}
              headTitle="Copy Store 01: (Wembley) menu to multiple Store?"
            />
          </BasicModal>
        )}
      </Box>
      {tabIndex === 0 && (
        <>
          <div className={classes.rectangle}>
            <div className={classes.inner}>
              <div className={`${classes.selectDropdown} mainMenuBuiltinStyle`}>
                <FormControl sx={{ minWidth: 100 }}>
                  <Select
                    labelId="radio-select-label"
                    id="radio-select"
                    value={selectedValue}
                    onChange={handleChangedrop}
                    renderValue={(selected) => selected}
                  >
                    {localMenuData?.map((item) => (
                      <MenuItem
                        value={item.menuTitle}
                        className="selectBuiltinStyleMenu"
                      >
                        <Radio
                          checked={selectedValue === item.menuTitle}
                          value={item.menuTitle}
                          name="radio-buttons"
                          inputProps={{ "aria-label": item.menuTitle }}
                        />
                        <ListItemText primary={item.menuTitle} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={`${classes.addNewDrop}`} ref={ref}>
                <Button
                  customClass={classes.newdropbtn}
                  onClick={handleAddMenu}
                >
                  Add New <img src={caretDown} alt="" />
                </Button>
                {showMenuDrop && (
                  <div className={`${classes.addNewDropInner}`}>
                    <ul>
                      <li onClick={handleOpenItem}>
                        <img src={itemIcon} alt="" /> Item
                      </li>
                      <li onClick={handleOpenCategory}>
                        <img src={categoryIcon} alt="" /> Category
                      </li>
                      <li onClick={handleOpenMenu}>
                        <img src={menuIcon} alt="" /> Menu
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className={classes.editText}>
              <p>Every day from 11:30 AM - 10:00 PM</p>
              <Button
                outlineBlack
                customClass={classes.editbtn}
                onClick={handleEditMenu}
              >
                <img src={editIcon} alt="" /> Edit menu
              </Button>
            </div>
            <div className={classes.dateSearchContainer}>
              <div className={classes.searchInputContainer}>
                <SearchIcon className={classes.searchIcon} />
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className={classes.searchInput}
                />
              </div>
            </div>
          </div>

          <div className={`${classes.Managementtabs} accordianHolder`}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Chef’s Specialties</Typography>{" "}
                <small>
                  {" "}
                  <div onClick={handleEditCategory}>
                    <img src={editIcon} alt="" /> Edit Category
                  </div>
                </small>
              </AccordionSummary>
              <AccordionDetails>
                <DraggableTable
                  columns={columns}
                  rows={rows}
                  setRows={setRows}
                  actionButton
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  colImage="item"
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography>Grilled/Flamed Chicken Meals</Typography>{" "}
                <small>
                  {" "}
                  <div onClick={handleEditCategory}>
                    <img src={editIcon} alt="" /> Edit Category
                  </div>
                </small>
              </AccordionSummary>
              <AccordionDetails>
                <DraggableTable
                  columns={columns}
                  rows={rows}
                  setRows={setRows}
                  actionButton
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  colImage="item"
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography>Prawn & Fish Dishes</Typography>{" "}
                <small>
                  {" "}
                  <div onClick={handleEditCategory}>
                    <img src={editIcon} alt="" /> Edit Category
                  </div>
                </small>
              </AccordionSummary>
              <AccordionDetails>
                <DraggableTable
                  columns={columns}
                  rows={rows}
                  setRows={setRows}
                  actionButton
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  colImage="item"
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                aria-controls="panel4d-content"
                id="panel4d-header"
              >
                <Typography>Traditional Desserts</Typography>{" "}
                <small>
                  {" "}
                  <div onClick={handleEditCategory}>
                    <img src={editIcon} alt="" /> Edit Category
                  </div>
                </small>
              </AccordionSummary>
              <AccordionDetails>
                <DraggableTable
                  columns={columns}
                  rows={rows}
                  setRows={setRows}
                  actionButton
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  colImage="item"
                />
              </AccordionDetails>
            </Accordion>
          </div>
          {editCategory && (
            <BasicModal
              isOpen={editCategory}
              closeModal={handleRoleClose}
              customWidth="994px"
            >
              <CreateCategory
                CategoryId={editCategoryID}
                headTitle={
                  editCategoryID ? "Edit Create Category" : "Create Category"
                }
              />
            </BasicModal>
          )}
          {deleteId && (
            <Delete
              isOpen={openDelete}
              onClose={handleClose}
              handleConfirmDelete={handleConfirmDelete}
              isDelete={isDelete}
              confirmDelete={confirmDelete}
              name="Menu"
            />
          )}
          {addMenu && (
            <BasicModal
              isOpen={addMenu}
              closeModal={handleRoleClose}
              customWidth="994px"
            >
              <NewMenu
                onSubmit={
                  editMenuDataSend ? handleUpdateMenuForm : handleAddMenuForm
                }
                headTitle="Add New Menu"
                setSelectedDays={setSelectedDays}
                selectedDays={selectedDays}
                editdata={editMenuDataSend}
              />
            </BasicModal>
          )}

          {addItem && (
            <BasicModal
              isOpen={addItem}
              closeModal={handleRoleClose}
              customWidth="994px"
            >
              <NewItem
                onSubmit={handleUpdateItemForm}
                headTitle={getCatogoryEdit ? "Edit Item" : "Add New Item"}
                editCatiID={getCatogoryEdit}
              />
            </BasicModal>
          )}

          {addCategory && (
            <BasicModal
              isOpen={addCategory}
              closeModal={handleRoleClose}
              customWidth="994px"
            >
              <CreateCategory
                onSubmit={handleUpdateCategoryForm}
                headTitle="Create Category"
              />
            </BasicModal>
          )}
        </>
      )}
      {tabIndex === 1 && (
        <>
          <div className={classes.rectangle}>
            <div className={classes.inner2}>
              <h1 onClick={toggleDropdown}>Choice Group</h1>
            </div>
            <div className={classes.editTextChoice}>
              <p>
                This is where you’ll find the groups of choices your customers
                can add to their items.
              </p>
              <Button onClick={handleChoiceGroup} customClass={classes.btn2}>
                Create Choice Group
              </Button>
            </div>
            <div className={classes.dateSearchContainer}>
              <div className={classes.searchInputContainer}>
                <SearchIcon className={classes.searchIcon} />
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className={classes.searchInput}
                />
              </div>
            </div>
          </div>
          <div className="choicesTableStyle">
            {addChoiceGroup && (
              <BasicModal
                isOpen={addChoiceGroup}
                closeModal={handleRoleClose}
                customWidth="994px"
              >
                <CreateChoiceGroup
                  onSubmit={handleUpdateChoiceForm}
                  headTitle="Create Choice Group"
                />
              </BasicModal>
            )}
            <DraggableTable
              columns={columnsChoices}
              rows={rowsChoices}
              setRows={setRowsChoices}
              // handleEdit={handleEdit}
              // handleDelete={handleDelete}
              choicesTable
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Management;
