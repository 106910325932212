import React from "react";
import classes from "./profile.module.scss";
import profile_img from '../../../assets/images/profile_img.png'
import ArrowRight from '../../../assets/images/Arrow-right.png'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import EditProfile from "../editProfile";
import ChangePassword from "../changepassword";
import PrivicyPolicy from "../privicypolicy";
import TermsOfUse from "../termsofuse";
import "./profileBuiltin.scss"
import { Height } from "@mui/icons-material";
import Customization from "../customization";

const Profile = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
    return (
        <>
            <div className={classes.Container}>
                <div className={classes.ProfileTabContainer}>
                    <div className={classes.Profile}>
                        <div className={classes.ProfileImg}>
                            <img src={profile_img} alt="profile-img" />
                        </div>
                        <div className={classes.ProfileDesc}>
                            <h3>John Duo</h3>
                            <p>johnduo@gmail.com</p>
                        </div>
                    </div>
                    <Box className={`${classes.tabs} profileTabBuiltin`}>
                        <div className={`notifications ${classes.notifications}`}>
                            <p>Notification</p>
                            <label className={`switch ${classes.switch}`}>
                                <input type="checkbox" />
                                <span className={`slider round ${classes.slider}`}></span>
                            </label>
                        </div>
                        <Tabs value={value} onChange={handleChange} >
                            <Tab label="Edit Profile" {...a11yProps(0)} />
                            <Tab label="Change Password"  {...a11yProps(1)} />
                            <Tab label="Privicy Policy" {...a11yProps(2)} />
                            <Tab label="Terms of use" {...a11yProps(3)} />
                            <Tab label="Customization" {...a11yProps(4)} />
                        </Tabs>
                    </Box>
                </div>
                <div className={classes.ProfileTabs}>
                    <CustomTabPanel value={value} index={0}>
                        <EditProfile />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <ChangePassword />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <PrivicyPolicy />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <TermsOfUse />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={4}>
                        <Customization />
                    </CustomTabPanel>
                </div>
            </div>
        </>
    );
};

export default Profile;