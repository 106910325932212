import React, { useState } from "react";
import BasicModal from "../../components/shared/basicmodal";
import classes from "./manageRole.module.scss";
import { Box, CircularProgress, Grid } from "@mui/material";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import EastIcon from "@mui/icons-material/East";
import CustomizePermissionsModal from "./CustomizePermissionsModal";
import Button from "../../components/shared/customBtn";
import CustomInput from "../../../src/components/shared/customInput";
const newRoleSchema = Yup.object().shape({
  roleType: Yup.string().required("Please enter role type"),
  description: Yup.string().required("Please enter description"),
});
const AddNewRole = ({ isOpen, onClose, addRoleClose }) => {
  const [loading, setLoading] = useState(false);
  const [customizePermission, setCustomizePermission] = useState(false);
  const customizeHandler = () => {
    setCustomizePermission(true);
  };
  const handleClose = () => {
    setCustomizePermission(false);
    addRoleClose(false);
  };
  return (
    <>
      <BasicModal isOpen={isOpen} closeModal={onClose} customWidth="994px">
        <div className={classes.modalContent}>
          <div className={classes.headingWrap}>
            <h4>Add New Role</h4>
          </div>
          <div className={classes.defaultForm}>
            <Formik
              initialValues={{ roleType: "", description: "" }}
              // validationSchema={newRoleSchema}
              onSubmit={async (values) => {
                // setSignupFlow({...signupFlow , profileInfo: false, businessInfo : true });
                // setSignupData(values)
                console.log("values:", values);
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className={classes.inputHolder}>
                    <div className="formWrapper">
                      <Grid container spacing={1}>
                        <Grid item lg={6} sm={6}>
                          <CustomInput
                            label="Enter Role Type"
                            type="text"
                            name="roleType"
                            value={values.roleType}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter Text"
                            touched={touched.roleType}
                            errors={errors.roleType}
                          />
                        </Grid>
                        <Grid item lg={6} sm={6}>
                          <CustomInput
                            label="Description"
                            type="text"
                            name="description"
                            value={values.description}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter Description"
                            touched={touched.description}
                            errors={errors.description}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <button
                      className={classes.customizeBtn}
                      onClick={customizeHandler}
                    >
                      customize permissions <EastIcon />
                    </button>
                  </div>
                  <Button customClass={classes.roleBtn}>
                    {loading ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItem: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress color="inherit" />
                      </Box>
                    ) : (
                      "Save Changes!"
                    )}
                  </Button>
                  <div className={classes.signupLink}></div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </BasicModal>
      {customizePermission && (
        <CustomizePermissionsModal
          isOpen={customizePermission}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default AddNewRole;
