import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminLayout from "./components/adminLayout";
import NoPage from "./pages/nopage";
import ProtectedRoute from "./components/protectedRoute";
import Unauthorized from "./components/unauthorized";
import './index.css';
// auth links
import Login from "./pages/Auth/Login";
import SignUp from "./pages/Auth/SignUp";
import CompleteProfile from "./pages/Auth/SignUp/completeProfile";
import ResetPassword from "./pages/Auth/ResetPassword";
import VerifyOTP from "./pages/Auth/VerifyOTP";
import VerifyRegisterOTP from "./pages/Auth/VerifyOTP/verifyRegisterOTP";
import ChangePassword from "./pages/Auth/ChangePassword";
import Thankyou from "./pages/Auth/Thankyou";

// pages links
import Home from "./pages/home";
import LiveOrders from "./pages/LiveOrders";
import OrderHistory from "./pages/OrderHistory";
import MenuManagement from "./pages/MenuManagement";
import Overview from "./pages/Overview";
import ManageStores from "./pages/ManageStore";
import ManageCustomer from "./pages/ManageCustomer";
import ManageUsers from "./pages/ManageUser/";
import ManageRoles from "./pages/customersRole";
import CustomerReview from "./pages/CustomerReview/CustomerReview";
import Reports from "./pages/Reports";
import Settings from "./pages/settings";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        {/* Auth links  */}

        <Route path="/sign-in" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/complete-profile" element={<CompleteProfile />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-otp" element={<VerifyOTP />} />
        <Route path="/verify-register-otp" element={<VerifyRegisterOTP />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/thankyou" element={<Thankyou />} />

        {/* Pages links  */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <AdminLayout />
            </ProtectedRoute>
          }
        >
          <Route path="*" element={<NoPage />} />
          <Route
            index
            element={
              <ProtectedRoute requiredRole={["admin", "moderator", "user"]}>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute requiredRole={["admin", "moderator", "user"]}>
               <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/live-orders"
            element={
              <ProtectedRoute requiredRole={["admin"]}>
                <LiveOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/orders-history"
            element={
              <ProtectedRoute requiredRole={["admin", "moderator"]}>
                <OrderHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/menu-management"
            element={
              <ProtectedRoute requiredRole={["admin", "moderator", "user"]}>
                <MenuManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/store/overview"
            element={
              <ProtectedRoute requiredRole={["admin", "moderator", "user"]}>
                <Overview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/store/manage-stores"
            element={
              <ProtectedRoute requiredRole={["admin", "user"]}>
                <ManageStores />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customers/manage-customer"
            element={
              <ProtectedRoute requiredRole={["admin"]}>
                <ManageCustomer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customers/manage-user"
            element={
              <ProtectedRoute requiredRole={["admin"]}>
                <ManageUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customers/roles"
            element={
              <ProtectedRoute requiredRole={["admin"]}>
                <ManageRoles />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customers/customer-reviews"
            element={
              <ProtectedRoute requiredRole={["admin", "moderator"]}>
                <CustomerReview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <ProtectedRoute requiredRole={["admin"]}>
                <Reports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute requiredRole={["admin", "moderator", "user"]}>
                <Settings />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
