import React from "react";
import classes from "./dashboard.module.scss";
import "./dashboardBuiltin.scss";
import { LineChart } from "@mui/x-charts/LineChart";
const Revenue = () => {
  const pData = [1000, 2600, 1800, 3500, 2800, 1300, 3920];
  const xLabels = ["Jan", "Feb", "March", "April", "May", "Jun", "Jul"];
  return (
    <div className={classes.OrderSummaryWrap}>
      <div className={classes.textWrap}>
        <div className={classes.textHolder}>
          <h3>Revenue</h3>
          <p>Lorem ipsum dolor sit amet, consectetur</p>
        </div>
      </div>
      <div className="lineChart">
        <span>
          <strong>£456</strong> Apr 18th, 2023
        </span>

        <LineChart
          xAxis={[{ scaleType: "point", data: xLabels }]}
          series={[
            {
              data: pData,
              // label: "£456 Apr 18th, 2023",
              area: true,
              color: "#F57E2A",
            },
          ]}
          // width={500}
          height={300}
          margin={{ left: 40, right: 30, top: 30, bottom: 30 }}
          grid={{ vertical: true, horizontal: true }}
        />
      </div>
    </div>
  );
};

export default Revenue;
