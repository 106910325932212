import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Typography, Select as MUISelect, Grid } from "@mui/material";
import Button from "../../components/shared/customBtn";
import classes from "./management.module.scss";
import Note from "../../assets/images/note.svg";
import CheckCircleIcon from "../../assets/images/checkIcon.png";
import Delete from "../../components/shared/deleteModal";
import CustomInput from "../../components/shared/customInput";

const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const NewMenu = ({
  onSubmit,
  headTitle,
  selectedDays,
  setSelectedDays,
  editdata,
}) => {
  const validationSchema = Yup.object().shape({
    menuTitle: Yup.string().required("Menu Title is required"),
    menuDesc: Yup.string().required("Menu Description is required"),
    startTime: Yup.string().required("Start Time is required"),
    endTime: Yup.string().required("End Time is required"),
  });

  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDelete = (id) => {
    setDeleteId(id);

    setIsDelete(true);
    setOpenDelete(true);
  };
  console.log("deleteId", deleteId);
  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
    setConfirmDelete(true);
  };

  const handleToggle = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay !== day)
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  return (
    <>
      <Formik
        initialValues={
          editdata && Object.keys(editdata).length > 0
            ? {
                menuTitle: editdata && editdata?.menuTitle,
                menuDesc: editdata && editdata?.menuDesc,
                startTime: editdata && editdata?.startTime,
                endTime: editdata && editdata?.endTime,
              }
            : {
                menuTitle: "",
                menuDesc: "",
                startTime: "",
                endTime: "",
              }
        }
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
        }) => (
          <Form className={classes.menuForm} onSubmit={handleSubmit}>
            <Typography className={classes.title}>{headTitle}</Typography>
            <hr />
            <div className={classes.header}>
              <h6>Menu Details</h6>
            </div>
            <div className={classes.choices}>
              <img src={Note} alt="Note icon" />
              <h6>
                Customers will be able to order from a menu between the hours
                specified. Each menu contains items that are grouped by
                categories.
              </h6>
            </div>

            <Grid container spacing={1}>
              <Grid item lg={6}>
                <CustomInput
                  label="Menu Title"
                  type="text"
                  name="menuTitle"
                  value={values.menuTitle}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Menu Title "
                  touched={touched.menuTitle}
                  errors={errors.menuTitle}
                />
              </Grid>
              <Grid item lg={6}>
                <CustomInput
                  label="Menu Description"
                  type="text"
                  name="menuDesc"
                  value={values.menuDesc}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Menu Description "
                  touched={touched.menuDesc}
                  errors={errors.menuDesc}
                />
              </Grid>
            </Grid>

            <div className={classes.header}>
              <h6>Menu Availability</h6>
            </div>
            <div className={classes.choices}>
              <img src={Note} alt="Note icon" />
              <h6>
                Select when this menu will be available to customers. Only one
                menu can be available at any given time. If you have two or more
                menus on the same day, make sure there is no gap in time between
                menus.
              </h6>
            </div>

            <div className={classes.weekStyle}>
              <ul>
                {daysOfWeek.map((day) => (
                  <li
                    key={day}
                    onClick={() => handleToggle(day)}
                    style={{
                      border: selectedDays.includes(day)
                        ? `1px solid #F57E2A`
                        : "1px solid #CDCDCD",
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    {selectedDays.includes(day) && (
                      <img src={CheckCircleIcon} alt="" />
                    )}
                    <span
                      style={{
                        color: selectedDays.includes(day)
                          ? `#313131`
                          : "#777777",
                      }}
                    >
                      {day}
                    </span>
                  </li>
                ))}
              </ul>
            </div>

            <Grid container spacing={1} sx={{ paddingBottom: "20px" }}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <CustomInput
                  label="Start Time"
                  type="select"
                  name="startTime"
                  value={values.startTime}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Select option"
                  touched={touched.startTime}
                  errors={errors.startTime}
                  options={[
                    "12:00 AM",
                    "01:00 AM",
                    "02:00 AM",
                    "03:00 AM",
                    "04:00 AM",
                    "05:00 AM",
                    "06:00 AM",
                    "07:00 AM",
                    "08:00 AM",
                    "09:00 AM",
                    "10:00 AM",
                    "11:00 AM",
                    "12:00 PM",
                    "01:00 PM",
                    "02:00 PM",
                    "03:00 PM",
                    "04:00 PM",
                    "05:00 PM",
                    "06:00 PM",
                    "07:00 PM",
                    "08:00 PM",
                    "09:00 PM",
                    "10:00 PM",
                    "11:00 PM",
                  ]}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <CustomInput
                  label="End Time"
                  type="select"
                  name="endTime"
                  value={values.endTime}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Select option"
                  touched={touched.endTime}
                  errors={errors.endTime}
                  options={[
                    "12:00 AM",
                    "01:00 AM",
                    "02:00 AM",
                    "03:00 AM",
                    "04:00 AM",
                    "05:00 AM",
                    "06:00 AM",
                    "07:00 AM",
                    "08:00 AM",
                    "09:00 AM",
                    "10:00 AM",
                    "11:00 AM",
                    "12:00 PM",
                    "01:00 PM",
                    "02:00 PM",
                    "03:00 PM",
                    "04:00 PM",
                    "05:00 PM",
                    "06:00 PM",
                    "07:00 PM",
                    "08:00 PM",
                    "09:00 PM",
                    "10:00 PM",
                    "11:00 PM",
                  ]}
                />
              </Grid>
            </Grid>
            {deleteId && (
              <Delete
                isOpen={openDelete}
                onClose={handleClose}
                handleConfirmDelete={handleConfirmDelete}
                isDelete={isDelete}
                confirmDelete={confirmDelete}
                name="Menu"
              />
            )}
            <div className={classes.btncontainer}>
              {editdata && (
                <>
                  <Button
                    type="button"
                    fontsize="0.75rem"
                    customWidth="432px"
                    customClass={classes.createBtn}
                    shadowOrange
                    onClick={() => handleDelete(editdata)}
                  >
                    Delete Menu
                  </Button>
                </>
              )}
              <Button
                type="submit"
                fontsize="0.75rem"
                customWidth={editdata ? "432px" : "350px"}
                customClass={classes.createBtn}
                shadowOrange
                grey={editdata ? true : false}
                shadowGrey={editdata ? true : false}
              >
                {editdata ? "Save Menu" : "Create Menu"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewMenu;
