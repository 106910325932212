import React, { useState, useEffect, useRef } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import classes from "./SignUp.module.scss";
import { Link } from "react-router-dom";
import { userLogin } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { LOGIN } from "../../../constants";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import signupBanner from "../../../assets/images/signup4.png";
import logo from "../../../assets/images/logo.png";
import step2 from "../../../assets/images/step2.svg";
import arrowleft from "../../../assets/images/arrowleft.svg";
import CustomInput from "../../../components/shared/customInput";

const legalSchema = Yup.object().shape({
  taxInfo: Yup.string().required("Please select your Tax Info"),
  taxNum: Yup.string().required("Please enter your tax number"),
  IdType: Yup.string().required("Please select your proof type"),
  IdNum: Yup.string().required("Please enter your ID number"),
  isLicense: Yup.string().required("Please choose license status"),
  isStockExchange: Yup.string().required("Please choose stock exchange status"),
  isbusinessReg: Yup.string().required("Please choose business register"),
  isbusinessRegFederal: Yup.string().required(
    "Please choose business register federal"
  ),
});
const BusinessLegal = ({
  setSignupData,
  setSignupFlow,
  signupFlow,
  signupData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleBack = () => {
    setSignupFlow({ ...signupFlow, businessInfo: true, legalInfo: false });
  };

  return (
    <div className={classes.mainSignup}>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={{
            taxInfo: "",
            taxNum: "",
            IdType: "",
            IdNum: "",
            isLicense: "",
            isStockExchange: "",
            isbusinessReg: "",
            isbusinessRegFederal: "",
          }}
          validationSchema={legalSchema}
          onSubmit={async (values) => {
            // setSignupData(values);
            setSignupFlow({
              ...signupFlow,
              legalInfo: false,
              accountInfo: true,
            });
            console.log("values:", values);
            // try {
            //   const res = await userLogin(values);
            //   if (res?.status == 200) {
            //     dispatch({
            //       type: LOGIN,
            //       payload: res.data,
            //     });
            //     localStorage.setItem("userData", JSON.stringify(res.data));
            //     setTimeout(() => {
            //       setLoading(false);
            //       navigate("/");
            //     }, 2000);
            //   }
            // } catch (error) {
            //   setErrorMsg(error.response.data.error.message);
            // }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formHeader}>
                <div className={classes.backBtn}>
                  <button onClick={handleBack}>
                    {" "}
                    <img src={arrowleft} alt="" /> Back
                  </button>
                </div>
                <img src={step2} alt="stepper" className={classes.stepper} />
                <img src={logo} alt="logo" className={classes.signUpLogo} />
                <h1>
                  Provide <span>Legal Stuff!</span>
                </h1>
                <p>We need to verify your business.</p>
              </div>
              <CustomInput
                label="Tax Information"
                type="select"
                name="taxInfo"
                value={values.taxInfo}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Select option"
                touched={touched.taxInfo}
                errors={errors.taxInfo}
                options={["Tax Included", "Not Included"]}
              />

              <CustomInput
                label="Tax Number"
                type="text"
                name="taxNum"
                value={values.taxNum}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="enter tax number"
                touched={touched.taxNum}
                errors={errors.taxNum}
              />

              <Grid container spacing={1}>
                <Grid item lg={6} style={{ width: "100%" }}>
                  <CustomInput
                    label="ID Proof Type"
                    type="select"
                    name="IdType"
                    value={values.IdType}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Select option"
                    touched={touched.IdType}
                    errors={errors.IdType}
                    options={["ID", "Other"]}
                  />
                </Grid>
                <Grid item lg={6} style={{ width: "100%" }}>
                  <CustomInput
                    label="Enter ID Number"
                    type="text"
                    name="IdNum"
                    value={values.IdNum}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="enter id number"
                    touched={touched.IdNum}
                    errors={errors.IdNum}
                  />
                </Grid>
              </Grid>

              <CustomInput
                heading="Do you have an operating license?"
                type="radio"
                name="isLicense"
                options={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ]}
                touched={touched.isLicense}
                errors={errors.isLicense}
              />

              <CustomInput
                heading="Is your business part of listed company in stock exchange?"
                type="radio"
                name="isStockExchange"
                options={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ]}
                touched={touched.isStockExchange}
                errors={errors.isStockExchange}
              />
              <CustomInput
                heading="Is your business registered under any one of the sanctioned country?"
                type="radio"
                name="isbusinessReg"
                options={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ]}
                touched={touched.isbusinessReg}
                errors={errors.isbusinessReg}
              />
              <CustomInput
                heading="Is your business registered with Pakistan's Federal Bureau of Revenue?"
                type="radio"
                name="isbusinessRegFederal"
                options={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ]}
                touched={touched.isbusinessRegFederal}
                errors={errors.isbusinessRegFederal}
              />

              <button type="submit">
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  "Next"
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div className={classes.rightBanner}>
        <div className={classes.bannerHeading}>
          <div className={classes.bannerHeadingeee}>
            Increase sales <br /> with Foodie!
          </div>
        </div>
        <img src={signupBanner} alt="login banner" height="100%" />
      </div>
    </div>
  );
};

export default BusinessLegal;
