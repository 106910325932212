import React, { useRef, useState } from "react";
import {  Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Typography, MenuItem, Select as MUISelect, Grid } from "@mui/material";
import Button from "../../components/shared/customBtn";
import classes from "./management.module.scss";
import uploadbranch from "../../assets/images/uploadbranch.svg";
import steakimg from "../../assets/images/steak.png";
import editIcon from "../../assets/images/editIcon.png";
import { Checkbox } from "@mui/material";
import CreateChoiceGroup from "./CreateChoiceGroup";
import BasicModal from "../../components/shared/basicmodal";
import AddVariation from "./AddVariation";
import { Select, Radio, FormControl, ListItemText } from "@mui/material";
import CustomInput from "../../components/shared/customInput";


const NewItem = ({ onSubmit, headTitle, editCatiID }) => {
  const validationSchema = Yup.object().shape({
    ItemName: Yup.string().required("Enter Item Name"),
    ItemDesc: Yup.string().required("Enter Item Description"),
    category: Yup.string().required("Select Category"),
    menu: Yup.string().required("Select Menu"),
    ItemPrice: Yup.string().required("Enter Item Price"),
    DiscountPrice: Yup.string().required("Enter Discount Price "),
    file: Yup.mixed().required("Please select a image"),
  });

  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState("Available");
  const [addChoiceGroup, setAddChoiceGroup] = useState(false);
  const [editVariationID, setEditVariationID] = useState();
  const [openVariModal, setOpenVariModal] = useState(null);
  const [editChoiceID, setEditChoiceID] = useState();
  const [openChoiceModal, setOpenChoiceModal] = useState(null);
  const [variations, setVariations] = useState([]);
  const [choiceGroups, setChoiceGroups] = useState([]);
  const handleChangedrop = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      setFieldValue("file", file);
    }
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleChoiceGroup = () => {
    setAddChoiceGroup(true);
  };

  const handleChoiceClose = () => {
    setAddChoiceGroup(false);
    setOpenChoiceModal(false);
  };

  const handleSaveChoiceGroup = (choiceGroupData) => {
    setChoiceGroups([...choiceGroups, choiceGroupData]);
    setAddChoiceGroup(false);
    setOpenChoiceModal(false);
  };

  const handleEditChoiceGroup = (index, updatedChoiceGroup) => {
    const updatedChoiceGroups = [...choiceGroups];
    updatedChoiceGroups[index] = updatedChoiceGroup;
    setChoiceGroups(updatedChoiceGroups);
  };

  const openVariationModal = (id) => {
    setEditVariationID(id);
    setOpenVariModal(true);
  };
  const handleVariationClose = () => {
    setOpenVariModal(false);
  };

  console.log("variationsvariations", variations);
  return (
    <Formik
      initialValues={
        editCatiID
          ? {
              ItemName: editCatiID.item,
              ItemDesc: editCatiID.description,
              category: editCatiID.category,
              menu: editCatiID.menu,
              ItemPrice: editCatiID.price,
              DiscountPrice: editCatiID.price,
              file: null,
            }
          : {
              ItemName: "",
              ItemDesc: "",
              category: "",
              menu: "",
              ItemPrice: "",
              DiscountPrice: "",
              file: null,
            }
      }
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => (
        <Form className={classes.Itemform} onSubmit={handleSubmit}>
          <div className={classes.header}>
            <Typography className={classes.title}>{headTitle}</Typography>
            <hr />
          </div>

          <div className={classes.topcontent}>
            <h6>Item Details</h6>
            <div className={`${classes.ItemDropdown} ItemBuiltinStyle`}>
              <FormControl sx={{ minWidth: 100 }}>
                {/* <InputLabel id="radio-select-label">Select Option</InputLabel> */}{" "}
                <Select
                  labelId="radio-select-label"
                  id="radio-select"
                  value={selectedValue}
                  onChange={handleChangedrop}
                  renderValue={(selected) => selected}
                >
                  <MenuItem value="Available" className="AvailableMenu">
                    <Radio
                      checked={selectedValue === "Available"}
                      value="Available"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "Available" }}
                    />
                    <ListItemText primary="Available" />
                  </MenuItem>
                  <MenuItem value="Un-Available" className="AvailableMenu">
                    <Radio
                      checked={selectedValue === "Un-Available"}
                      value="Un-Available"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "Un-Available" }}
                    />
                    <ListItemText primary="Un-Available" />
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div
            className={classes.uploadsection}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <div className={classes.uploadsection}>
              <div className={classes.uploadcontainer}>
                {editCatiID ? (
                  <div className={classes.uploadField}>
                    {preview ? (
                      <img
                        src={preview}
                        alt="Uploaded"
                        className={classes.uploadedImage}
                        style={{ width: "100px" }}
                      />
                    ) : (
                      <img
                        src={steakimg}
                        alt="steak"
                        className={classes.uploadPlaceholder}
                        onClick={handleBrowseClick}
                      />
                    )}
                    <h1>Change Image</h1>

                    <input
                      id="file"
                      name="file"
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={(event) =>
                        handleImageChange(event, setFieldValue)
                      }
                    />
                  </div>
                ) : (
                  <div className={classes.uploadField}>
                    {preview ? (
                      <img
                        src={preview}
                        alt="Uploaded"
                        className={classes.uploadedImage}
                        style={{ width: "100px" }}
                      />
                    ) : (
                      <img
                        src={uploadbranch}
                        alt="Placeholder"
                        className={classes.uploadPlaceholder}
                        onClick={handleBrowseClick}
                      />
                    )}
                    <h1>Upload Drag Photo</h1>
                    <p>
                      You can upload up to 2MB, Image
                      <br /> (JPG, PNG)
                    </p>

                    <input
                      id="file"
                      name="file"
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={(event) =>
                        handleImageChange(event, setFieldValue)
                      }
                    />
                  </div>
                )}

                <div className={classes.errorMsg}>
                  <ErrorMessage name="file" />
                </div>
              </div>
            </div>
          </div>

          <Grid container spacing={1} sx={{ paddingTop: "20px" }}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <CustomInput
                label="Item Name"
                type="text"
                name="ItemName"
                value={values.ItemName}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Enter Item Name "
                touched={touched.ItemName}
                errors={errors.ItemName}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <CustomInput
                label="Item Description"
                type="text"
                name="ItemDesc"
                value={values.ItemDesc}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Enter Item Description "
                touched={touched.ItemDesc}
                errors={errors.ItemDesc}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ paddingBottom: "20px" }}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <CustomInput
                label="Select Category"
                type="select"
                name="category"
                value={values.category}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Select option"
                touched={touched.category}
                errors={errors.category}
                options={[
                  "Chef’s Specialties",
                  "Grilled/Flamed Chicken Meals",
                  "Prawn & Fish Dishes",
                  "Traditional Desserts",
                ]}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <CustomInput
                label="Select Menu"
                type="select"
                name="menu"
                value={values.menu}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Select option"
                touched={touched.menu}
                errors={errors.menu}
                options={[
                  "Main Menu",
                  "Breakfast Menu",
                  "Lunch Menu",
                  "Dinner Menu",
                ]}
              />
            </Grid>
          </Grid>

          <hr />

          <Grid container spacing={1} sx={{ padding: "20px 0px" }}>
            <Grid item lg={6}>
              <Grid container spacing={1}>
                <Grid item lg={12} sm={12}>
                  <div className={classes.Inputprice}>
                    <h4>Price & Offer</h4>
                    <p>
                      Add Price & an Offer for the item to show your customers.
                    </p>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <CustomInput
                    label="Item Price"
                    type="text"
                    name="ItemPrice"
                    value={values.ItemPrice}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter Item Price "
                    touched={touched.ItemPrice}
                    errors={errors.ItemPrice}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <CustomInput
                    label="Discount Price"
                    type="text"
                    name="DiscountPrice"
                    value={values.DiscountPrice}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter Discount Price "
                    touched={touched.DiscountPrice}
                    errors={errors.DiscountPrice}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={classes.Inputvariation}>
                <h4>Variations</h4>
                <p>
                  Add a variation of this item comes in sizes (e.g. Small,
                  Med...)
                </p>
                <div className={classes.editcontent}>
                  {/* <h5>“0” variation added</h5> */}
                  <h5>
                    {/* {variations ? `${variations?.variations?.length} variation added` : 0} */}
                    {`${variations?.length} variation added`}
                  </h5>

                  {variations.length > 0 && (
                    <div>
                      <Button
                        outlineBlack
                        customWidth="119px"
                        fontsize="0.75rem"
                        customClass={classes.editbtn}
                        onClick={() => openVariationModal(variations)}
                      >
                        <img src={editIcon} alt="" /> Edit Variation
                      </Button>
                    </div>
                  )}
                </div>
              </div>

              <Button
                fontsize="1rem"
                customWidth="413px"
                customClass={classes.variationBtn}
                grey
                shadowGrey
                onClick={() => openVariationModal(null)}
              >
                + Add Variation
              </Button>
            </Grid>
          </Grid>
          {openVariModal && (
            <BasicModal
              isOpen={openVariModal}
              closeModal={handleVariationClose}
              customWidth="994px"
            >
              <AddVariation
                editID={editVariationID}
                setEditVariationID={setEditVariationID}
                headTitle={editVariationID ? "Edit Variation" : "Add Variation"}
                setVariations={setVariations}
                variations={variations}
                setOpenVariModal={setOpenVariModal}
              />
            </BasicModal>
          )}
          <hr />

          <div className={classes.groupchoices}>
            <div className={classes.heading}>
              <h1>Choice Groups</h1>
              <h3>Add Choices for this item that customers can choose.</h3>
            </div>

            {addChoiceGroup && (
              <BasicModal
                isOpen={addChoiceGroup}
                closeModal={handleChoiceClose}
                customWidth="994px"
              >
                <CreateChoiceGroup
                  onSubmit={handleSaveChoiceGroup}
                  headTitle="Create Choice Group"
                />
              </BasicModal>
            )}
            <Button
              onClick={handleChoiceGroup}
              fontsize="0.875rem"
              customWidth="187px"
              customClass={classes.choicebtn}
              outline
            >
              + Create Choice Group
            </Button>
          </div>

          <div className={classes.choices}>
            {choiceGroups.map((group, index) => (
              <div key={index} className={classes.choiceContainer}>
                <div className={classes.checkwrapper}>
                  <div className={`${classes.checkboxWrap} checkboxWrap`}>
                    <Checkbox />
                  </div>

                  <div className={classes.wrapper}>
                    <h6>{group.choiceGroup}</h6>
                    <p>
                      Required (Selected {group.minimumChoices}),{" "}
                      {group.choices.length} Choices
                    </p>
                  </div>
                </div>
                <div className={classes.wrapper}>
                  <h6>
                    {group.choices.map((choice) => choice.Choice).join(", ")}
                  </h6>
                </div>

                <div className={classes.wrapper}>
                  <Button
                    lightOrange
                    customWidth="84px"
                    fontsize="0.75rem"
                    customClass={classes.editbtn}
                    onClick={() => {
                      setEditChoiceID(index);
                      setOpenChoiceModal(true);
                    }}
                  >
                    <img src={editIcon} alt="" /> Edit
                  </Button>
                  {openChoiceModal !== null && (
                    <BasicModal
                      isOpen={openChoiceModal}
                      closeModal={handleChoiceClose}
                      customWidth="994px"
                    >
                      <CreateChoiceGroup
                        ChoiceID={choiceGroups[editChoiceID]}
                        onSubmit={(updatedGroup) =>
                          handleEditChoiceGroup(editChoiceID, updatedGroup)
                        }
                        headTitle="Edit Choice Group"
                      />
                    </BasicModal>
                  )}
                </div>
              </div>
            ))}
          </div>

          <hr />

          <Button
            type="submit"
            shadowOrange
            fontsize="1rem"
            customWidth="350px"
            customClass={classes.submitBtn}
          >
            Save Items
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default NewItem;
