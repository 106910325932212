import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import classes from "./dashboard.module.scss";
import "./dashboardBuiltin.scss"
import forkwhite from "../../assets/images/forkwhite.png";
import revenuewhite from "../../assets/images/revenuewhite.png";
import orderwhite from "../../assets/images/orderwhite.png";
import OrderSummary from "./OrderSummary";
import Revenue from "./Revenue";
import TableFilter from "../../components/shared/tableFilter";
import Button from "../../components/shared/customBtn";
import { Container } from "@mui/material";
import Foodimage from "../../assets/images/foodimage.png"
import dropImg from "../../assets/images/drop.svg";
import acceptImg from "../../assets/images/accept.svg";
import rejectImg from "../../assets/images/reject.svg";
import detailImg from "../../assets/images/detail.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import fork from "../../assets/images/fork.svg";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
// ];
const Home = () => {
  const navigate = useNavigate();
  const names = ["Foodie Gulberg", "Foodie Johar Town", "Foodie Askari 11"];
  const recentOrders = [
    {
      id: '#01373812',
      name: 'Hassan Ali',
      description: 'Top 10 Must-Try Dishes in Lahore',
      address: '65L Main Gulberg, Lahore',
      image: Foodimage,
      topDishes: ['Dish 1', 'Dish 2', 'Dish 3'],
      price: '$7.4',
      quantity: 3,
      statustype: 'Pending'
    },
    // Add more orders as needed
    {
      id: '#01373812',
      name: 'Hassan Ali',
      description: 'Top 10 Must-Try Dishes in Lahore',
      address: '65L Main Gulberg, Lahore',
      image: Foodimage,
      topDishes: ['Dish 1', 'Dish 2', 'Dish 3'],
      price: '$7.4',
      quantity: 3,
      statustype: 'Delivered'
    }
  ];
  const TrendingMenu = [
    {

      item: "Chicken curry special with cucumber",
      itemImage: Foodimage,
      price: '$7.4',
      order: 'Orders 89x'
    },
    {

      item: "Chicken curry special with cucumber",
      itemImage: Foodimage,
      price: '$7.4',
      order: 'Orders 89x'
    }
  ]
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const handleOpenDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handleCloseDropdown = () => {
    setOpenDropdownIndex(null);
  };

  // drop func 
  const [restaurantName, setRestaurantName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRestaurantName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [showDrop, setShowDrop] = useState(false);
  const dropdownRef = useRef(null);

  const handleDrop = () => {
    setShowDrop((prev) => !prev);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleDrop();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
  }, []);
  return (
    <div>
      <div className={classes.mainDashboard}>
        <div className={classes.heading}>
          <div className={classes.headingText}>
            <h1>Dashboard</h1>
            <p>Hi, Hassan. Welcome back to Foodie!</p>
          </div>
          <div className={`${classes.selectDropdown} mainBuiltinStyle`}>
            <FormControl sx={{ m: 1, minWidth: 50 }}>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                displayEmpty
                multiple
                value={restaurantName}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select Option</em>;
                  }
                  if (selected.length === 1) {
                    return selected[0];
                  }
                  return `${selected[0]}, ...`;
                }}
                startAdornment={<InputAdornment position="start">
                  <img
                    src={fork}
                    alt="fork"
                    style={{
                      marginRight: "8px",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </InputAdornment>}
              >

                <MenuItem disabled value="" className="selectBuiltinStylePlaceholder">
                  <em>Select Option</em>
                </MenuItem>
                {names.map((name) => (
                  <MenuItem key={name} value={name} className="selectBuiltinStyleHeader">
                    <Checkbox checked={restaurantName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
                <div className={classes.dropLink}>
                  <Link to="#">None</Link>
                  <Link to="#">All</Link>
                </div>
              </Select>
            </FormControl>
          </div>
        </div>
        <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div className={classes.menuBox}>
              <div className={classes.menuText}>
                <h2>54</h2>
                <p>Total Menus</p>
              </div>
              <div className={classes.menuIcon}>
                <span>
                  <img src={forkwhite} alt="icon" />
                </span>
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div className={classes.menuBox}>
              <div className={classes.menuText}>
                <h2>£35000</h2>
                <p>Total Revenue</p>
              </div>
              <div className={classes.menuIcon}>
                <span>
                  <img src={revenuewhite} alt="icon" />
                </span>
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <div className={classes.menuBox}>
              <div className={classes.menuText}>
                <h2>6</h2>
                <p>Total Orders</p>
              </div>
              <div className={classes.menuIcon}>
                <span>
                  <img src={orderwhite} alt="icon" />
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.OrderHolder}>
        <OrderSummary />
        <Revenue />
      </div>
      <Grid container spacing={3}>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <div className={`${classes.parentRecentOrder} parentOrders`}>

            <div className={classes.parentRecentOrderRequest}>
              <div>
                <h3>Recent Order Request</h3>
                <p>Lorem ipsum dolor</p>
              </div>
              <Button outlineGrey>
                Newest <KeyboardArrowDownIcon style={{ color: 'grey' }} />
              </Button>
            </div>
            <div>
              {/* {recentOrders.map((order, index) => (
                <div className={classes.parentOrderRequest}>
                  <img src={order.image} alt={order.name} />       
                  <div className={classes.textOrderRequest}>
                    <div className={classes.styleOrderRequest}>
                      <div>s
                      <span>{order.description}</span>
                      <span class={classes.classesubtext}>{order.id}</span>
                      </div>
                      <div>
                      <span>{order.name}<span class={classes.classesubtext}>{order.address}</span></span>
                      </div>
                    </div>

                    <p>{order.price}<span className={classes.counting}>x3</span></p>
                    <div className={`${classes.status} statusType`}>{order.statustype}</div>
                    <div className={classes.dropdownWrapper} id={`dropdown-${index}`}>
                      <div
                        className={classes.verticalcircles}
                        onClick={() => handleOpenDropdown(index)}
                      >
                        <div class={classes.circle}></div>
                        <div class={classes.circle}></div>
                        <div class={classes.circle}></div>
                      </div>
                      {openDropdownIndex === index && (
                        <div className={classes.customDropContent}>
                          <div
                            className={classes.dropdownItem}
                            onClick={handleCloseDropdown}
                            style={{ color: "#34A853" }}
                          >
                            <img src={acceptImg} alt="accept" />
                            <h5>Accept Order</h5>
                          </div>
                          <div
                            className={classes.dropdownItem}
                            onClick={handleCloseDropdown}
                            style={{ color: "#E21D25" }}
                          >
                            <img src={rejectImg} alt="reject" />
                            <h5>Reject Order</h5>
                          </div>

                          <div
                            className={classes.dropdownItem}
                            onClick={handleCloseDropdown}
                            style={{ color: "#777777" }}
                          >
                            <img src={detailImg} alt="detail" />
                            <h5>View Details</h5>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
            
              ))} */}



<div className={classes.orderContainer}>
                {recentOrders.map((order, index) => (
                  <div className={classes.orderRow} key={index}>
                    {/* Image Section */}
                    <div className={classes.orderImage}>
                      <img src={order.image} alt={order.name} />
                    </div>
                    <div className={classes.contentHolder}>
                      <div className={classes.content}>
                        <div className={classes.orderDescription}>
                          {order.description} <br />
                          <span>{order.id}</span>
                        </div>
                        <div className={classes.orderName}>
                          {order.name} <br />
                          <span>{order.address}</span>
                        </div>
                      </div>
                      <div className={classes.content}>
                        <div><span className={classes.counting}>{order.price}x3</span></div>
                        <div className={`${classes.status} statusType`}>{order.statustype}</div>
                      </div>
                    </div>
                    <div className={classes.dropdownWrapper} id={`dropdown-${index}`}>
                      <div className={classes.verticalcircles} onClick={() => handleOpenDropdown(index)}>
                        <div className={classes.circle}></div>
                        <div className={classes.circle}></div>
                        <div className={classes.circle}></div>
                      </div>
                    {openDropdownIndex === index && (
                      <div className={classes.customDropContent}>
                        <div className={classes.dropdownItem} onClick={handleCloseDropdown} style={{ color: "#34A853" }}>
                          <img src={acceptImg} alt="accept" />
                          <h5>Accept Order</h5>
                        </div>
                        <div className={classes.dropdownItem} onClick={handleCloseDropdown} style={{ color: "#E21D25" }}>
                          <img src={rejectImg} alt="reject" />
                          <h5>Reject Order</h5>
                        </div>
                        <div className={classes.dropdownItem} onClick={handleCloseDropdown} style={{ color: "#777777" }}>
                          <img src={detailImg} alt="detail" />
                          <h5>View Details</h5>
                        </div>
                      </div>
                    )}
                    </div>
                  </div>
                ))}
              </div>



            </div>
          </div>

        </Grid>

        <Grid item lg={4} md={4} sm={12} xs={12}>
          <div className={classes.trendingMenus}>
            <h3>Daily Trending Menus</h3>
            <p>Lorem ipsum dolor</p>
            {TrendingMenu.map((order, index) =>
              <div className={`${classes.menuSetting} ${classes.borderBottom}`} key={order.id}>
                <div className={classes.parentImageText}>
                  <img src={order.itemImage} alt="" />
                  <div>
                    <span className={classes.imageText}>#{index + 1}</span>
                  </div>
                </div>
                <div className={classes.textContainer}>
                  <div className={classes.itemName}>
                    {order.item}
                  </div>
                  <div className={classes.lowerMenuSetting}>
                    <div className={classes.itemPrice}>
                      {order.price}
                    </div>
                    <div className={classes.itemOrder}>
                      {order.order}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
