import React from "react";
import "./style.scss";
const NoPage = () => {
  return (
    <div className="mainSection">
          Page Not Found
    </div>
  );
};

export default NoPage;
