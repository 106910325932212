import React, { useState, useEffect, useRef } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import classes from "./SignUp.module.scss";
import { Link } from "react-router-dom";
import { userLogin } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { LOGIN } from "../../../constants";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import signupBanner from "../../../assets/images/signup5.png";
import logo from "../../../assets/images/logo.png";
import step3 from "../../../assets/images/step3.svg";
import arrowleft from "../../../assets/images/arrowleft.svg";
import CustomInput from "../../../components/shared/customInput";

const accountSchema = Yup.object().shape({
  bankName: Yup.string().required("Please enter your bank name"),
  accountTitle: Yup.string().required("Please enter your bank account title"),
  ibanNum: Yup.string().required("Please enter your IBAN number  "),
});
const BusinessAccount = ({
  setSignupData,
  setSignupFlow,
  signupFlow,
  signupData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleBack = () => {
    setSignupFlow({ ...signupFlow, legalInfo: true, accountInfo: false });
  };

  return (
    <div className={classes.mainSignup}>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={{ bankName: "", accountTitle: "", ibanNum: "" }}
          validationSchema={accountSchema}
          onSubmit={async (values) => {
            setSignupData(values);
            navigate("/thankyou");
            console.log("values:", values);
            // try {
            //   const res = await userLogin(values);
            //   if (res?.status == 200) {
            //     dispatch({
            //       type: LOGIN,
            //       payload: res.data,
            //     });
            //     localStorage.setItem("userData", JSON.stringify(res.data));
            //     setTimeout(() => {
            //       setLoading(false);
            //       navigate("/");
            //     }, 2000);
            //   }
            // } catch (error) {
            //   setErrorMsg(error.response.data.error.message);
            // }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formHeader}>
                <div className={classes.backBtn}>
                <button onClick={handleBack}> <img src={arrowleft} alt="" /> Back</button>
                </div>
                <img src={step3} alt="stepper" className={classes.stepper} />
                <img src={logo} alt="logo" className={classes.signUpLogo}/>
                <h1>
                  Link your <span>Bank Account! </span>
                </h1>
                <p>
                  Please link your bank account to receive payments, your
                  details are secure & encrypted with us.
                </p>
              </div>
              <CustomInput
                label="Bank Name"
                type="text"
                name="bankName"
                value={values.bankName}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="enter bank name"
                touched={touched.bankName}
                errors={errors.bankName}
              />

              <Grid container spacing={1}>
                <Grid item lg={6} style={{ width: "100%" }}>
                  <CustomInput
                    label="Bank Account Title"
                    type="text"
                    name="accountTitle"
                    value={values.accountTitle}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="enter account title"
                    touched={touched.accountTitle}
                    errors={errors.accountTitle}
                  />
                </Grid>
                <Grid item lg={6} style={{ width: "100%" }}>
                  <CustomInput
                    label="IBAN"
                    type="text"
                    name="ibanNum"
                    value={values.ibanNum}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="enter iban number"
                    touched={touched.ibanNum}
                    errors={errors.ibanNum}
                  />
                </Grid>
              </Grid>

              <button type="submit">
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  "Submit"
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div className={classes.rightBanner}>
        <div className={classes.bannerHeading}>
          <div className={classes.bannerHeadingeee}>
            Increase sales <br /> with Foodie!
          </div>
        </div>
        <img src={signupBanner} alt="login banner" height="100%" />
      </div>
    </div>
  );
};

export default BusinessAccount;
