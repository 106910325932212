import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Button from "../../components/shared/customBtn";
import classes from "./management.module.scss";
import { Grid, Box } from "@mui/material";
import DeleteImage from "../../assets/images/del.svg";
import AddIcon from "@mui/icons-material/Add";

// Component for rendering input fields with labels and error messages
const InputField = ({
  label,
  name,
  type,
  handleChange,
  handleBlur,
  value,
  error,
  touched,
}) => (
  <Grid item xs={12} sm={6}>
    <div className={classes.inputMain}>
      <label>{label}</label>
      <Field
        type={type}
        name={name}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        className={`${classes.inputField} ${
          error && touched ? classes.errorMsgLine : null
        }`}
      />
      <div className={classes.errorMsg}>
        <ErrorMessage name={name} />
      </div>
    </div>
  </Grid>
);

const AddVariationPop = ({
  editID,
  headTitle,
  variations,
  setVariations,
  setOpenVariModal,
  setEditVariationID,
}) => {
  const validationSchema = Yup.object().shape({
    variations: Yup.array().of(
      Yup.object().shape({
        variation: Yup.string().required("Variation Name is required"),
        enterprice: Yup.string().required("Price is required"),
      })
    ),
  });
  // const [variations, setVariations] = useState([]);
  const addVariation = (newVariation) => {
    setVariations([...variations, newVariation]);
  };


  return (
    <Formik
      initialValues={{
        variations: editID
          ? editID
          : [{ variation: "", enterprice: "" }],
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Log or process the array of variation objects
        setVariations(values.variations);
        setEditVariationID(null);
        setOpenVariModal(false);
      }}
    >
      {({ values, handleChange, handleBlur, errors, touched }) => (
        <Form className={`${classes.variationParentModal} parent`}>
          <div className={classes.title}>{headTitle}</div>
          <div className={classes.userFormHolder}>
            <div>
              <h3>Variations</h3>
              <p>
                Add a variation of this item comes in sizes (e.g. Small, Medium,
                Large).
              </p>
            </div>

            <FieldArray name="variations">
              {({ push, remove }) =>
                <>
              {values?.variations?.map((variation, index) => (
                <div key={index}>
                  <Grid container spacing={3}>
                    <InputField
                      label="Variation Name"
                      name={`variations[${index}].variation`}
                      type="text"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={variation.variation}
                      touched={touched.variations?.[index]?.variation}
                      error={errors.variations?.[index]?.variation}
                    />
                    <InputField
                      label="Enter Price"
                      name={`variations[${index}].enterprice`}
                      type="text"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={variation.enterprice}
                      touched={touched.variations?.[index]?.enterprice}
                      error={errors.variations?.[index]?.enterprice}
                    />
                  </Grid>
                  <Box className={classes.deleteBtn}>
                    <Button outline onClick={() => remove(index)}>
                      <img src={DeleteImage} alt="Delete" /> Delete
                    </Button>
                  </Box>
                  <hr className={classes.border} />
                </div>
              ))}
              <Box className={classes.buttonContainer}>
                <Button
                  type="button"
                  customClass={classes.btnAddVariation}
                  fontsize="0.75rem"
                  customWidth="350px"
                  onClick={() => push({ variation: "", enterprice: "" })}
                >
                  <AddIcon />
                  Add Variation
                </Button>
                <Button
                  type="submit"
                  customClass={classes.btnAddUsers}
                  shadowOrange
                  fontsize="0.75rem"
                  customWidth="350px"
                  // onClick={ ()=> addVariation()}
                >
                  {editID ? "Save Changes" : "Save Variation"}
                </Button>
              </Box>
            </>
              }
            </FieldArray>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddVariationPop;
