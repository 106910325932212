import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import dragImg from "../../../assets/images/dragImg.png";
import classes from "./dragTable.module.scss";
import "./dragTableBuiltin.scss";
import edit from "../../../assets/images/edit.svg";
import deleteIcon from "../../../assets/images/del.svg";

const DraggableTable = ({ columns, rows, setRows ,actionButton, handleEdit, handleDelete, colImage, choicesTable}) => {
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(rows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRows(items);
  };


  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="table">
        {(provided) => (
          <div className={classes.mainDragTable}>
            {choicesTable ? 
            <table {...provided.droppableProps} ref={provided.innerRef}>
            <thead>
              <tr>
         
                {columns.map((col) => (
                  col.key === "availableChoice" ?
                  <th key={col.key}> <span className='greenBall'></span> {col.label}</th>
                  : 
                  col.key === "unavailableChoices" ?
                  <th key={col.key}><span className='redBall'></span> {col.label}</th>
                  :
                  <th key={col.key}>{col.label}</th>
                ))}
                 {actionButton && (
                  <th>Actions</th>
              )}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <Draggable key={row.id} draggableId={row.id} index={index}>
                  {(provided, snapshot) => (
                    <tr
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`table-row `}
                    >
                        {/* <td ><img src={dragImg} alt="" /></td> */}
                      {columns.map((col) => (
                        col.key === colImage  ?
                        <td key={col.key}> 
                        <div className={classes.imagecontent}>
                          <img src={row.imgName} alt="" className={classes.customerImage} /> {row[col.key]}
                        </div>
                          </td> 
                          : 
                          
                          col.key === "choicesGroups"  ?
                        <td key={col.key}> 
                        <div className={classes.imagecontent}>
                          <div><img src={dragImg} alt="" /></div>
                          <div>{row[col.key]} <p>{row.choiceGroupText}</p></div>
                         
                        </div>
                          </td>
                           : 
                          
                           col.key === "position"  ?
                         <td key={col.key}> 
                         <div className={classes.positioncontent}>
                          <img src={dragImg} alt="" /> {row[col.key]}
                         </div>
                           </td>
                        :
                        <td key={col.key}> {row[col.key]}</td>
                        ))}
                       {actionButton && (
                    <td >
                      <div className={classes.actionBtn}>
                        {handleEdit && (
                          <div
                            className={classes.edit}
                            onClick={() => handleEdit(row.id)}
                          >
                            <img src={edit} alt="edit" />
                          </div>
                        )}
                       
                        {handleDelete && (
                          <div
                            className={classes.deletecontent}
                            onClick={() => handleDelete(row?.id)}
                          >
                            <img src={deleteIcon} alt="Delete" />
                          </div>
                        )}
                       
                      </div>
                    </td>
                  )}
                    </tr>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          </table>
            :
            <table {...provided.droppableProps} ref={provided.innerRef}>
            <thead>
              <tr>
              <th>#</th>
                {columns.map((col) => (
                  <th key={col.key}>{col.label}</th>
                ))}
                 {actionButton && (
                  <th>Actions</th>
              )}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <Draggable key={row.id} draggableId={row.id} index={index}>
                  {(provided) => (
                    <tr
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                        <td ><img src={dragImg} alt="" /></td>
                      {columns.map((col) => (
                        col.key === colImage  ?
                        <td key={col.key}> 
                        <div className={classes.imagecontent}>
                          <img src={row.imgName} alt="" className={classes.customerImage} /> {row[col.key]}
                        </div>
                          </td>
                        :
                        <td key={col.key}> {row[col.key]}</td>
                        ))}
                       {actionButton && (
                    <td >
                      <div className={classes.actionBtn}>
                        {handleEdit && (
                          <div
                            className={classes.edit}
                            onClick={() => handleEdit(row)}
                          >
                            <img src={edit} alt="edit" />
                          </div>
                        )}
                       
                        {handleDelete && (
                          <div
                            className={classes.deletecontent}
                            onClick={() => handleDelete(row?.id)}
                          >
                            <img src={deleteIcon} alt="Delete" />
                          </div>
                        )}
                       
                      </div>
                    </td>
                  )}
                    </tr>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          </table>
            }
         
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableTable;
