import React, { useState, useEffect } from "react";
import ReusableTable from "../../components/shared/resuabletable";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import classes from "./orderhistory.module.scss";
import "./orderhistoryBuiltin.scss";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TableFilter from "../../components/shared/tableFilter";

const Index = () => {
  const [customers, setCustomers] = useState([]);
  const [customersDeep, setCustomersDeep] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const handleData = async () => {
      try {
        const rest = await fetch(
          "https://65db5d083ea883a1529197e5.mockapi.io/orderhistory"
        );
        const data = await rest.json();
        setCustomers(data);
        setCustomersDeep(data);
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      }
    };
    handleData();
  }, []);

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
  };

  useEffect(() => {
    if (searchTerm.length > 2) {
      const filteredCustomers = customersDeep.filter((customer) =>
        customer?.name?.toLowerCase().includes(searchTerm)
      );
      setCustomers(filteredCustomers);
    } else {
      setCustomers(customersDeep);
    }
  }, [searchTerm, customersDeep]);

  const columns = [
    { label: "Order ID", field: "id" },
    { label: "Customer Name", field: "customer_name" },
    { label: "Location", field: "location" },
    { label: "Type", field: "type" },
    { label: "Payment", field: "payment" },
    { label: "Status", field: "status" },
    { label: "Price", field: "price" },
  ];
  const [all, setAll] = useState("");

  const handleFilterDrop = (event) => {
    setAll(event.target.value);
  };

  return (
    <div className={classes.order_section}>
      <div className={classes.headerContainer}>
      <TableFilter
          heading="Order History"
          tableLength={customers.length}
          searchPlaceholder="Search in Orders"
          handleSearch={handleSearch}
          datePicker
          filterDrop
          handleFilterDrop={handleFilterDrop}
          dropValue={all}
        />
      </div>
      <ReusableTable
        columns={columns}
        data={customers}
        searchTerm={searchTerm}
        pagination
        rowPerPage={10}
        isStatus
        isCustName
        isPayment
      />
    </div>
  );
};

export default Index;
