import React, { useState } from "react";
import Orders from "./Orders";
import Summary from "./Summary";
import Delete from "./editorder";
import classes from "./liveorders.module.scss";

const Index = () => {
  const [openDelete, setOpenDelete] = useState(false);
  const [handlenewItem, setHandlenewItem] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);

  const handleClose = () => {
    setOpenDelete(false);
    setHandlenewItem(false);
    setOpenCancel(false);
  };

  const OpenModal = () => {
    setOpenDelete(true);
  };

  const openCancelModal = () => {
    setOpenCancel(true);
  };

  const editOrder = () => {
    setHandlenewItem(true);
    setOpenDelete(false);
  };

  return (
    <div>
      <div className={classes.liveorder}>
        <Orders />
        <Summary OpenModal={OpenModal} openCancelModal={openCancelModal} />
      </div>

      <Delete
        isOpen={openDelete}
        onClose={handleClose}
        editOrder={editOrder}
        handlenewItem={handlenewItem}
        openCancel={openCancel}
      />
    </div>
  );
};

export default Index;
