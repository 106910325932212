import dashboardicon from "../../assets/images/dashboardicon.svg"
import ordericon from "../../assets/images/ordericon.svg"
import menuicon from "../../assets/images/menuicon.svg"
import storeicon from "../../assets/images/storeicon.svg"
import customericon from "../../assets/images/customericon.svg"
import reporticon from "../../assets/images/reporticon.svg"
import settingicon from "../../assets/images/settingicon.svg"

const MenuData = [
  {
    title: "Dashboard",
    link: "/dashboard",
    icon: dashboardicon,
    roles: ["admin", "moderator", "user"],
  },
  {
    title: "Orders",
    dropdown: true,
    icon: ordericon,
    items: [
      { title: "Live Orders", link: "/orders/live-orders" , roles: ["admin"], },
      { title: "Orders History", link: "/orders/orders-history", roles: ["admin", "moderator"], }
    ],
   
  },
  {
    title: "Menu Managment",
    link: "/menu-management",
    icon: menuicon,
    roles: ["admin", "moderator", "user"],
  },
  {
    title: "Store",
    dropdown: true,
    icon: storeicon,
    items: [
      { title: "Overview", link: "/store/overview", roles: ["admin", "moderator", "user"], },
      { title: "Manage stores", link: "/store/manage-stores", roles: ["admin", "user"], }
    ],
   
  },
  {
    title: "Customers",
    dropdown: true,
    icon: customericon,
    items: [
      { title: "Manage Customer", link: "/customers/manage-customer", roles: ["admin"], },
      { title: "Manage User", link: "/customers/manage-user", roles: ["admin"], },
      { title: "Manage Roles", link: "/customers/roles", roles: ["admin"], },
      { title: "Customer Reviews", link: "/customers/customer-reviews", roles: ["admin", "moderator"], }
    ],
   
  },
  {
    title: "Reports",
    link: "/reports",
    icon: reporticon,
    roles: ["admin"],
  },
  {
    title: "Settings",
    link: "/settings",
    icon: settingicon,
    roles: ["admin", "moderator", "user"],
  }
];


  export default MenuData;