import React, { useState, useEffect, forwardRef } from "react";
import classes from "./SignUp.module.scss";
import { userLogin } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { LOGIN } from "../../../constants";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
// import ProfileInfo from "./profileInfo";
import BusinessInfo from "./businessInfo";
// import BusinessLocation from "./businessLocation";
import BusinessLegal from "./businessLegal";
import BusinessAccount from "./businessAccount";

const CompleteProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signupData, setSignupData] = useState();
  const [signupFlow, setSignupFlow] = useState({
    businessInfo: true,
    legalInfo: false,
    accountInfo: false,
  });

  return (
    <div className={classes.containerSignup}>
      {signupFlow?.businessInfo && (
        <BusinessInfo
          setSignupData={setSignupData}
          setSignupFlow={setSignupFlow}
          signupFlow={signupFlow}
          signupData={signupData}
        />
      )}
      {signupFlow?.legalInfo && (
        <BusinessLegal
          setSignupData={setSignupData}
          setSignupFlow={setSignupFlow}
          signupFlow={signupFlow}
          signupData={signupData}
        />
      )}
      {signupFlow?.accountInfo && (
        <BusinessAccount
          setSignupData={setSignupData}
          setSignupFlow={setSignupFlow}
          signupFlow={signupFlow}
          signupData={signupData}
        />
      )}
    </div>
  );
};

export default CompleteProfile;
