import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import classes from "./manageRole.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import Dashboard from "./Dashboard";
import BasicModal from "../../components/shared/basicmodal";
import Button from "../../components/shared/customBtn";

const CustomizePermissionsModal = ({ isOpen, onClose }) => {
  const [value, setValue] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [permissionsDeep, setPermissionssDeep] = useState([]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const handleSearch = (event) => {
    const searchValue = event.target.value.trim().toLowerCase();
    setSearchTerm(searchValue);
  };

  useEffect(() => {
    if (searchTerm.length > 2) {
      const filteredCustomers = permissionsDeep.filter((customer) =>
        customer?.name?.toLowerCase().includes(searchTerm)
      );
      setPermissions(filteredCustomers);
    } else {
      setPermissions(permissionsDeep);
    }
  }, [searchTerm, permissionsDeep]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <BasicModal isOpen={isOpen} closeModal={onClose} customClass="994px">
        <div className={classes.permissions}>
          <div className={classes.headingWrap}>
            <h4>Customize Permissions</h4>
            <div className={classes.searchInputContainer}>
              <SearchIcon className={classes.searchIcon} />
              <input
                type="text"
                placeholder="Search in Permissions"
                value={searchTerm}
                onChange={handleSearch}
                className={classes.searchInput}
              />
            </div>
          </div>
          <div className="tabsHolder">
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
                display: "flex",
                height: 224,
              }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider" }}
              >
                <Tab label="Dashboard" {...a11yProps(0)} />
                <Tab label="Orders" {...a11yProps(1)} />
                <Tab label="Items" {...a11yProps(2)} />
                <Tab label="Branches" {...a11yProps(3)} />
                <Tab label="Customers" {...a11yProps(4)} />
                <Tab label="Settings" {...a11yProps(5)} />
                <Tab label="Sign Out" {...a11yProps(6)} />
              </Tabs>
              <TabPanel value={value} className="customTab" index={0}>
              <Dashboard currentDashboard="Dashboard" />
              </TabPanel>
              <TabPanel value={value} className="customTab" index={1}>
              <Dashboard currentDashboard="Orders" />
              </TabPanel>
              <TabPanel value={value} className="customTab" index={2}>
              <Dashboard currentDashboard="Items" />
              </TabPanel>
              <TabPanel value={value} className="customTab" index={3}>
              <Dashboard currentDashboard="Branches" />
              </TabPanel>
              <TabPanel value={value} className="customTab" index={4}>
              <Dashboard currentDashboard="Customers" />
              </TabPanel>

              <TabPanel value={value} className="customTab" index={5} >
              <Dashboard currentDashboard="Setting" />
              </TabPanel>

              <TabPanel value={value} className="customTab" index={6}>
              <Dashboard currentDashboard="Sign-out" />
              </TabPanel>
            </Box>
          </div>
          <div className={classes.btnWrap}>
            <Button  onClick={onClose} customClass={classes.permissionBtn}>
              Confirm
            </Button>
          </div>
        </div>
      </BasicModal>
    </>
  );
};
export default CustomizePermissionsModal;
