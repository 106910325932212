import React, { useRef, useState } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Typography, Grid } from "@mui/material";
import Button from "../../components/shared/customBtn";
import classes from "./managestore.module.scss";
import uploadbranch from "../../assets/images/uploadbranch.svg";
import CustomInput from "../../components/shared/customInput";
const NewBranch = ({ onSubmit, headTitle }) => {
  const validationSchema = Yup.object().shape({
    branchName: Yup.string().required("Branch Name is required"),
    branchAddress: Yup.string().required("Branch Address is required"),
    file: Yup.mixed().required("Please select a branch image"),
  });

  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      setFieldValue("file", file);
    }
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <Formik
      initialValues={{
        branchName: "",
        branchAddress: "",
        file: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => (
        <Form className={classes.storeform} onSubmit={handleSubmit}>
          <div className={classes.header}>
            <Typography className={classes.title}>{headTitle}</Typography>
            <hr />
          </div>

          <Grid container spacing={1}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <CustomInput
                label="Branch Name"
                type="text"
                name="branchName"
                value={values.branchName}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Enter Text"
                touched={touched.branchName}
                errors={errors.branchName}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <CustomInput
                label="Branch Address"
                type="text"
                name="branchAddress"
                value={values.branchAddress}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Enter Text"
                touched={touched.branchAddress}
                errors={errors.branchAddress}
              />
            </Grid>
          </Grid>
          <hr></hr>

          <div
            className={classes.uploadsection}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <h4>
              Branch Image <span>(optional)</span>
            </h4>
            <div className={classes.uploadcontainer}>
              <div className={classes.uploadField}>
                {preview ? (
                  <img
                    src={preview}
                    alt="Uploaded"
                    className={classes.uploadedImage}
                    style={{ width: "100px" }}
                  />
                ) : (
                  <img
                    src={uploadbranch}
                    alt="Placeholder"
                    className={classes.uploadPlaceholder}
                    onClick={handleBrowseClick}
                  />
                )}
                <h1>Upload Image</h1>
                <p>
                  You can upload up to 2MB, Image
                  <br /> (JPG, PNG)
                </p>
                <Button
                  customClass={classes.browsebtn}
                  fontsize="0.813rem"
                  customWidth="122px"
                  onClick={handleBrowseClick}
                >
                  Browse
                </Button>

                <input
                  id="file"
                  name="file"
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={(event) => handleImageChange(event, setFieldValue)}
                />
              </div>
              <div className={classes.errorMsg}>
                <ErrorMessage name="file" />
              </div>
            </div>
          </div>

          <div className={classes.buttonContainer}>
            <Button
              customClass={classes.storebtn}
              shadowOrange
              fontsize="0.75rem"
              customWidth="350px"
              type="submit"
            >
              Add Store
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NewBranch;
