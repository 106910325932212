import React, { useState, useEffect } from "react";
import ReusableTable from "../../components/shared/resuabletable";
import Delete from "../../components/shared/deleteModal";
import classes from "./managestore.module.scss";
import BasicModal from "../../components/shared/basicmodal";
import TableFilter from "../../components/shared/tableFilter";
import NewBranch from "./newBranch";
import EditBranch from "./editBranch";

const ManageStore = () => {
  const [user, setUser] = useState([]);
  const [userDeep, setUserDeep] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [error, setError] = useState(null);
  const [addUser, setAddUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [editPass, setEditPass] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editBranchData, setEditBranchData] = useState(null);

  useEffect(() => {
    const handleData = async () => {
      try {
        const rest = await fetch(
          "https://667416f375872d0e0a950aed.mockapi.io/Users"
        );
        const data = await rest.json();
        setUser(data);
        setUserDeep(data);
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      }
    };
    handleData();
  }, []);

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
  };

  useEffect(() => {
    if (searchTerm.length > 2) {
      const filteredCustomers = userDeep.filter((customer) =>
        customer?.name?.toLowerCase().includes(searchTerm)
      );
      setUser(filteredCustomers);
    } else {
      setUser(userDeep);
    }
  }, [searchTerm, userDeep]);

  const columns = [
    { label: "#", field: "index" },
    { label: "Branch Image", field: "Created_at" },
    { label: "Branch Name", field: "Username" },
    { label: "Branch Address", field: "Email" },
  ];

  const handleRoleClose = () => {
    setAddUser(false);
    setEditUser(false);
    setEditPass(false);
    setEditBranchData(null); // Clear edit data when modal closes
  };

  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
  };

  const handleDelete = (id) => {
    setOpenDelete(true);
    setIsDelete(true);
    setDeleteId(id);
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
    setConfirmDelete(true);
  };

  const handleEdit = (data) => {
    setEditBranchData(data);
    setEditUser(true);
  };

  const addUserHandle = () => {
    setIsEditing(false);
    setAddUser(true);
  };

  const handleAddUser = (newUser) => {
    setUser((prevUsers) => [...prevUsers, newUser]);
    setUserDeep((prevUsers) => [...prevUsers, newUser]);
    setAddUser(false);
  };

  const handleEditUser = (updatedUser) => {
    const updatedUsers = user.map((u) =>
      u.id === updatedUser.id ? updatedUser : u
    );
    setUser(updatedUsers);
    setUserDeep(updatedUsers);
    setEditUser(false);
    setEditBranchData(null); // Clear edit data after editing
  };

  return (
    <div className={classes.mainStore}>
      <div className={classes.store_section}>
        <TableFilter
          heading="Manage Branches"
          tableLength={user.length}
          searchPlaceholder="Search in Branches"
          handleSearch={handleSearch}
          handleModalOpenLeft={addUserHandle}
          buttonTextLeft="Add New Branch"
          iconBtnLeft
        />

        <div>
          <ReusableTable
            columns={columns}
            data={user}
            dataDeep={userDeep}
            handleDelete={handleDelete}
            searchTerm={searchTerm}
            pagination
            rowPerPage={11}
            handleEdit={handleEdit}
            actionButton
            CreatedAt
          />
        </div>
        {deleteId && (
          <Delete
            isOpen={openDelete}
            onClose={handleClose}
            handleConfirmDelete={handleConfirmDelete}
            isDelete={isDelete}
            confirmDelete={confirmDelete}
            name="Branch"
          />
        )}
        {addUser && (
          <BasicModal
            isOpen={addUser}
            closeModal={handleRoleClose}
            customWidth="994px"
          >
            <NewBranch onSubmit={handleAddUser} headTitle="Add New Branch" />
          </BasicModal>
        )}
        {editUser && editBranchData && (
          <BasicModal
            isOpen={editUser}
            closeModal={handleRoleClose}
            customWidth="994px"
          >
            <EditBranch
              onSubmit={handleEditUser}
              headTitle="Edit Branch"
              initialValues={editBranchData}
            />
          </BasicModal>
        )}
      </div>
    </div>
  );
};

export default ManageStore;
