import React, { useState, useEffect, forwardRef } from "react";
import { Formik, Form } from "formik";
import classes from "./Login.module.scss";
import { Link } from "react-router-dom";
import { userLogin } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { LOGIN } from "../../../constants";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import loginBanner from "../../../assets/images/loginBanner.png";
import logo from "../../../assets/images/logo.png";
import CustomInput from "../../../components/shared/customInput";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email"
    ),
  password: Yup.string()
    .required("Please enter your password")
    .min(8, "Password must be at least 8 characters long"),
});
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={classes.mainLogin}>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={{ email: "", password: "", remember : false }}
          validationSchema={loginSchema}
          onSubmit={async (values) => {
            setLoading(true);
            navigate("/dashboard");
            console.log("values:", values);
            localStorage.setItem("authToken", JSON.stringify("31212"));
            localStorage.setItem('userRole', 'admin');
            // try {
            //   const res = await userLogin(values);
            //   if (res?.status == 200) {
            //     dispatch({
            //       type: LOGIN,
            //       payload: res.data,
            //     });
            //     localStorage.setItem("userData", JSON.stringify(res.data));
            //     setTimeout(() => {
            //       setLoading(false);
            //       navigate("/");
            //     }, 2000);
            //   }
            // } catch (error) {
            //   setErrorMsg(error.response.data.error.message);
            // }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formHeader}>
                <img src={logo} alt="logo" />
                <h1>
                  Welcome Back,<span>Sign In!</span>
                </h1>
              </div>
              <CustomInput
                label="Email Address"
                type="text"
                name="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="enter email"
                touched={touched.email}
                errors={errors.email}
              />
              <CustomInput
                label="Password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="enter password"
                touched={touched.password}
                errors={errors.password}
                eyeIcon
                setPasswordChange={setShowPassword}
                passwordChange={showPassword}
              />

              <div className={classes.rememberForgot}>
                <CustomInput
                  label="Remember me"
                  type="checkbox"
                  name="remember"
                  value={values.remember}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <Link to="/reset-password">
                  <span className={classes.forgotStyle}>Forgot Password?</span>
                </Link>
              </div>
              <button type="submit">
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  "Sign In"
                )}
              </button>
              <div className={classes.signupLink}>
                <div className={classes.signupRow}>
                  <span>Don't have account? </span>
                  <Link to="/sign-up" className={classes.linkSignup}>
                    Sign Up{" "}
                  </Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className={classes.rightBanner}>
        <div className={classes.bannerHeading}>
          Increase sales <br /> with Foodie!
        </div>
        <img src={loginBanner} alt="login banner" height="100%" />
      </div>
    </div>
  );
};

export default Login;
