import React from "react";
import { Typography } from "@mui/material";
import Button from "../../components/shared/customBtn";
import classes from "./management.module.scss";
import { Checkbox } from "@mui/material";

const CopyStore = ({ onSubmit, headTitle }) => {
  return (
    <form className={classes.copyform} onSubmit={onSubmit}>
      <div className={classes.header}>
        <Typography className={classes.title}>{headTitle}</Typography>
        <hr />
      </div>
       <div className={classes.selectedbranch}>
      <div className={classes.text}>
        <h6>
          The selected branch's menu will be fully replaced and offers that are
          currently applied will be deleted.
        </h6>
      </div>

      <div className={classes.checkwrapper}>
        <div className={`${classes.checkboxWrap} checkboxWrap`}>
          <Checkbox />
        </div>

        <div className={classes.wrapper}>
          <h6>Branch 01: Hounslow</h6>
        </div>
      </div>

      <div className={classes.checkwrapper}>
        <div className={`${classes.copyboxWrap} copyboxWrap`}>
          <Checkbox />
        </div>

        <div className={classes.wrapper}>
          <h6>Branch 01: Hounslow</h6>
        </div>
      </div>
      <hr />
      </div>

      <Button
        customClass={classes.copybtn}
        shadowOrange
        fontsize="1.375rem"
        customWidth="360px"
        type="submit"
      >
        Submit
      </Button>
    </form>
  );
};

export default CopyStore;
