import React, { useState } from "react";
import BasicModal from "../../components/shared/basicmodal/index";
import karahi from "../../assets/images/karahi.svg";
import shawarma from "../../assets/images/shawarma.svg";
import naan from "../../assets/images/naan.svg";
import tikka from "../../assets/images/tikka.svg";
import burger from "../../assets/images/burger.svg";
import gif from "../../assets/images/girl gif.svg";
import classes from "./liveorders.module.scss";
import "./liveordersBuiltIn.scss";
import group2 from "../../assets/images/group2.png";
import Button from "../../components/shared/customBtn";

const Delete = ({ isOpen, onClose, editOrder, handlenewItem, openCancel }) => {
  const customers = [
    {
      Name: "Chicken Karchi",
    },
    {
      Name: "Paratha Roll",
    },
    {
      Name: "Fresh Naan",
    },
    {
      Name: "Fresh Naan",
    },
    {
      Name: "Fresh Naan",
    },
  ];

  const initialStates = customers.map(() => ({
    count: 0,
    added: false,
  }));

  const [modal1, setModal1] = useState(initialStates);
  const [modal2, setModal2] = useState(initialStates);

  const increaseCount = (index, setState, state) => {
    const newState = state.map((item, i) =>
      i === index ? { ...item, count: item.count + 1 } : item
    );
    setState(newState);
  };

  const decreaseCount = (index, setState, state) => {
    const newState = state.map((item, i) =>
      i === index && item.count > 0 ? { ...item, count: item.count - 1 } : item
    );
    setState(newState);
  };

  const handleAdd = (index, setState, state) => {
    const newState = state.map((item, i) =>
      i === index ? { ...item, added: true } : item
    );
    setState(newState);
  };

  const handleInputChange1 = (index, event, setState, state) => {
    const newValue = parseInt(event.target.value) || 0;
    const newState = state.map((item, i) =>
      i === index ? { ...item, count: newValue } : item
    );
    setState(newState);
  };

  const handleInputChange2 = (index, event, setState, state) => {
    const newValue = parseInt(event.target.value) || 0;
    const newState = state.map((item, i) =>
      i === index ? { ...item, count: newValue } : item
    );
    setState(newState);
  };

  return (
    <>
      <BasicModal isOpen={isOpen} closeModal={onClose} customClass="editmodal">
        <div className={classes.modalheader}>
          <div className={classes.modalContent}>
            <div className={classes.table}>
              {customers.map((customer, index) => (
                <div key={index} className={classes.tableRow}>
                  {index === 0 && (
                    <img
                      src={karahi}
                      className={classes.dishimg}
                      alt={`Dish 1`}
                    />
                  )}
                  {index === 1 && (
                    <img
                      src={shawarma}
                      className={classes.dishimg}
                      alt={`Dish 2`}
                    />
                  )}
                  {index === 2 && (
                    <img
                      src={naan}
                      className={classes.dishimg}
                      alt={`Dish 3`}
                    />
                  )}
                  {index === 3 && (
                    <img
                      src={tikka}
                      className={classes.dishimg}
                      alt={`Dish 4`}
                    />
                  )}
                  {index === 4 && (
                    <img
                      src={burger}
                      className={classes.dishimg}
                      alt={`Dish 5`}
                    />
                  )}
                  <div className={classes.customerInfo}>
                    <h5>{customer.Name}</h5>
                    <h6>
                      <span className={classes.bold}>Category:</span> Main
                      Course Chicken Dishes
                    </h6>
                    <p>
                      From <span className={classes.bold}>$8.62</span>
                    </p>
                  </div>
                  <div className={classes.actionText}>
                    <div className={classes.numberInput}>
                      <button
                        className={classes.decbtn}
                        onClick={() => decreaseCount(index, setModal1, modal1)}
                      >
                        -
                      </button>
                      <input
                        className={classes.count}
                        type="text"
                        value={modal1[index].count}
                        onChange={(event) =>
                          handleInputChange1(index, event, setModal1, modal1)
                        }
                      />
                      <button
                        className={classes.incbtn}
                        onClick={() => increaseCount(index, setModal1, modal1)}
                      >
                        +
                      </button>
                    </div>
                    <img src={group2} alt="group2" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <h5 onClick={editOrder}>+ Add New Item</h5>
          <hr />

          <Button
            onClick={onClose}
            customClass={classes.customBtn1}
            shadowOrange
            customWidth="350px"
            fontsize="1rem"
          >
            Save & Add!
          </Button>
        </div>
      </BasicModal>

      <BasicModal
        isOpen={handlenewItem}
        closeModal={onClose}
        customClass="editmodal2"
      >
        <div className={classes.modalheader}>
          <div className={classes.modalContent}>
            <div className={classes.table}>
              {customers.map((customer, index) => (
                <div key={index} className={classes.tableRow}>
                  {index === 0 && (
                    <img
                      src={karahi}
                      className={classes.dishimg}
                      alt={`Dish 1`}
                    />
                  )}
                  {index === 1 && (
                    <img
                      src={shawarma}
                      className={classes.dishimg}
                      alt={`Dish 2`}
                    />
                  )}
                  {index === 2 && (
                    <img
                      src={naan}
                      className={classes.dishimg}
                      alt={`Dish 3`}
                    />
                  )}
                  {index === 3 && (
                    <img
                      src={tikka}
                      className={classes.dishimg}
                      alt={`Dish 4`}
                    />
                  )}
                  {index === 4 && (
                    <img
                      src={burger}
                      className={classes.dishimg}
                      alt={`Dish 5`}
                    />
                  )}
                  <div className={classes.customerInfo}>
                    <h5>{customer.Name}</h5>
                    <h6>
                      <span className={classes.bold}>Category:</span> Main
                      Course Chicken Dishes
                    </h6>
                    <p>
                      From <span className={classes.bold}>$8.62</span>
                    </p>
                  </div>
                  <div className={classes.actionText}>
                    {!modal2[index].added ? (
                      <Button
                        onClick={() => handleAdd(index, setModal2, modal2)}
                        customClass={classes.addbtn}
                        shadowOrange
                        fontsize="0.75rem"
                        customWidth="86px"
                      >
                        Add
                      </Button>
                    ) : (
                      <div style={{ display: "flex", gap: "10px" }}>
                        <div className={classes.numberInput}>
                          <button
                            className={classes.decbtn}
                            onClick={() =>
                              decreaseCount(index, setModal2, modal2)
                            }
                          >
                            -
                          </button>
                          <input
                            className={classes.count}
                            type="text"
                            value={modal2[index].count}
                            onChange={(event) =>
                              handleInputChange2(
                                index,
                                event,
                                setModal2,
                                modal2
                              )
                            }
                          />
                          <button
                            className={classes.incbtn}
                            onClick={() =>
                              increaseCount(index, setModal2, modal2)
                            }
                          >
                            +
                          </button>
                        </div>
                        <img src={group2} alt="group2" />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <Button
            onClick={onClose}
            customClass={classes.customBtn2}
            shadowOrange
            customWidth="350px"
            fontsize="1rem"
          >
            Add
          </Button>
        </div>
      </BasicModal>

      <BasicModal
        isOpen={openCancel}
        closeModal={onClose}
        customWidth="580px"
        customClass={classes.deleteStyle}
      >
        <div className={classes.modalContent}>
          <img src={gif} alt="recyclebin" />
          <h1>Are you sure you want to cancel this order?</h1>
          <Button
            onClick={onClose}
            customClass={classes.cancelbtn}
            shadowOrange
            customWidth="350px"
            fontsize="1rem"
          >
            Yes, Cancel
          </Button>
        </div>
      </BasicModal>
    </>
  );
};

export default Delete;
