import React, { useState } from "react";
import classes from "./changePassword.module.scss";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Field, Formik, Form, ErrorMessage } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Button from "../../../components/shared/customBtn";
import CustomInput from "../../../components/shared/customInput";
const ChangePassword = () => {
  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required("Current Password is required")
      .min(8, "Password must be at least 8 characters long"),
    newPassword: Yup.string()
      .required("New Password is required")
      .min(8, "Password must be at least 8 characters long"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm New Password is required"),
  });

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConPass, setShowConPass] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowNewPass = () => setShowNewPass(!showNewPass);
  const toggleShowConPass = () => setShowConPass(!showConPass);

  return (
    <div className={classes.changepassword}>
      <div className={classes.Desc}>
        <h1>Change Password</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore{" "}
        </p>
      </div>
      <div className={classes.defaultForm}>
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={changePasswordSchema}
          onSubmit={async (values) => {
            setLoading(true);
            console.log("values:", values);
            // Add your submit logic here
            setLoading(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                {/* <Grid item lg={12} xs={12}> */}
                {/* <div className={classes.inputMain}>
                    <label>Enter Current Password</label>
                    <div className={classes.eyeBox}>
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="currentPassword"
                        value={values.currentPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={`${classes.inputField} ${
                          errors.currentPassword && touched.currentPassword
                            ? classes.errorMsgLine
                            : ""
                        }`}
                        placeholder="Enter current password"
                      />
                      <IconButton
                        onClick={toggleShowPassword}
                        className={classes.eyeIcon}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>
                    <div className={classes.errorMsg}>
                      <ErrorMessage name="currentPassword" />
                    </div>
                  </div> */}
                {/* </Grid> */}
                <CustomInput
                  label="Enter Current Password"
                  type={showPassword ? "text" : "password"}
                  name="currentPassword"
                  value={values.currentPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter current password"
                  touched={touched.currentPassword}
                  errors={errors.currentPassword}
                  eyeIcon
                  setPasswordChange={setShowPassword}
                  passwordChange={showPassword}
                />
                {/* <div className={classes.inputMain}>
                                <label>Enter New Password</label>
                                <div className={classes.eyeBox}>
                                <Field
                                    type={showNewPass ? "text" : "password"}
                                    name="newPassword"
                                    value={values.newPassword}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className={`${classes.inputField} ${errors.newPassword && touched.newPassword ? classes.errorMsgLine : ""}`}
                                    placeholder="Enter new password"
                                />
                                <IconButton
                                    onClick={toggleShowNewPass}
                                    className={classes.eyeIcon}
                                >
                                    {showNewPass ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                </div>
                                <div className={classes.errorMsg}>
                                    <ErrorMessage name="newPassword" />
                                </div>
                            </div> */}
               
                <CustomInput
                  label="Enter New Password"
                  type={showNewPass ? "text" : "password"}
                  name="newPassword"
                  value={values.newPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="enter new password"
                  touched={touched.newPassword}
                  errors={errors.newPassword}
                  eyeIcon
                  setPasswordChange={setShowNewPass}
                  passwordChange={showNewPass}
                />
                {/* <div className={classes.inputMain}>
                  <label>Confirm New Password</label>
                  <div className={classes.eyeBox}>
                    <Field
                      type={showConPass ? "text" : "password"}
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={`${classes.inputField} ${
                        errors.confirmPassword && touched.confirmPassword
                          ? classes.errorMsgLine
                          : ""
                      }`}
                      placeholder="Confirm new password"
                    />
                    <IconButton
                      onClick={toggleShowConPass}
                      className={classes.eyeIcon}
                    >
                      {showConPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </div>
                  <div className={classes.errorMsg}>
                    <ErrorMessage name="confirmPassword" />
                  </div>
                </div> */}
                <CustomInput
                  label="Confirm New Password"
                  type={showConPass ? "text" : "password"}
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Confirm new password"
                  touched={touched.confirmPassword}
                  errors={errors.confirmPassword}
                  eyeIcon
                  setPasswordChange={setShowConPass}
                  passwordChange={showConPass}
                />
              </Grid>
              <div>
                <Button type="submit" shadowOrange>
                  Update Password!
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;
