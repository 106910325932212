import React, { useState } from "react";
import {  Formik, Form,  FieldArray } from "formik";
import * as Yup from "yup";
import { Typography, MenuItem, Select as MUISelect, Grid } from "@mui/material";
import Button from "../../components/shared/customBtn";
import classes from "./management.module.scss";
import { Select, Radio, FormControl, ListItemText } from "@mui/material";
import deleteicon from "../../assets/images/deleteicon.svg";
import reddelete from "../../assets/images/del.svg";
import Delete from "../../components/shared/deleteModal";
import CustomInput from "../../components/shared/customInput";

const CreateChoice = ({ ChoiceID, onSubmit, headTitle }) => {
  const validationSchema = Yup.object().shape({
    choiceGroup: Yup.string().required("Choice Group Name is required"),
    minimumChoices: Yup.string().required(
      "Minimum Number of Choices is required"
    ),
    maximumChoices: Yup.string().required(
      "Maximum Number of Choices is required"
    ),
    choices: Yup.array().of(
      Yup.object().shape({
        Choice: Yup.string().required("Choice Name is required"),
        Price: Yup.string().required("Price is required"),
        Availability: Yup.string().required("Availability is required"),
      })
    ),
  });

  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDelete = (id) => {
    setOpenDelete(true);
    setIsDelete(true);
    setDeleteId(id);
  };

  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
    setConfirmDelete(true);
  };

  return (
    <Formik
      initialValues={
        ChoiceID || {
          choiceGroup: "",
          minimumChoices: "",
          maximumChoices: "",
          choices: [{ Choice: "", Price: "", Availability: "Available" }],
        }
      }
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
      }) => (
        <Form className={classes.CreateChoiceForm} onSubmit={handleSubmit}>
          <Typography className={classes.title}>{headTitle}</Typography>
          <hr />
          <div className={classes.header}>
            <h6>Choice Group Details</h6>
          </div>

          <Grid container spacing={1}>
            <Grid item lg={12}>
              <CustomInput
                label="Choice Group Name"
                type="text"
                name="choiceGroup"
                value={values.choiceGroup}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Enter Choice Group Name "
                touched={touched.choiceGroup}
                errors={errors.choiceGroup}
              />
            </Grid>
          </Grid>

          <div className={classes.content}>
            <h6>Example: Choice of Slides, Choice of Extras</h6>
          </div>

          <Grid container spacing={1} sx={{ paddingBottom: "20px" }}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <CustomInput
                label="Minimum Number of Choices"
                type="select"
                name="minimumChoices"
                value={values.minimumChoices}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Select option"
                touched={touched.minimumChoices}
                errors={errors.minimumChoices}
                options={["0", "1", "2", "3", "4", "5"]}
              />
              <div className={classes.content}>
                <h6>Enter "0" if this group is optional for your customers.</h6>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <CustomInput
                label="Maximum Number of Choices"
                type="select"
                name="maximumChoices"
                value={values.maximumChoices}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Select option"
                touched={touched.maximumChoices}
                errors={errors.maximumChoices}
                options={["0", "1", "2", "3", "4", "5"]}
              />
              <div className={classes.content}>
                <h6>Enter "1" if your customers can only choose 1</h6>
              </div>
            </Grid>
          </Grid>

          <hr />

          <div className={classes.header}>
            <h6>Choice Group Details</h6>
          </div>
          <div className={classes.groupdetails}>
            <h6>
              Add choices for your customer to pick from, for example, French
              Fries or Salad. You can also add existing menu items as a choice.
            </h6>
          </div>

          <FieldArray name="choices">
            {({ push, remove }) => (
              <>
                {values.choices.map((choice, index) => (
                  <div key={index}>
                    {index > 0 && <hr />}{" "}
                    {/* Add <hr> before each choice except the first one */}
                    <div className={classes.topcontent}>
                      <div
                        className={`${classes.ChoiceDropdown} ChoiceBuiltin`}
                      >
                        <FormControl sx={{ minWidth: 100 }}>
                          <Select
                            labelId="radio-select-label"
                            id="radio-select"
                            name={`choices[${index}].Availability`}
                            value={choice.Availability}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            renderValue={(selected) => selected}
                          >
                            <MenuItem
                              value="Available"
                              className="AvailableMenu"
                            >
                              <Radio
                                checked={choice.Availability === "Available"}
                                value="Available"
                                name={`choices[${index}].Availability`}
                                inputProps={{ "aria-label": "Available" }}
                              />
                              <ListItemText primary="Available" />
                            </MenuItem>
                            <MenuItem
                              value="Un-Available"
                              className="AvailableMenu"
                            >
                              <Radio
                                checked={choice.Availability === "Un-Available"}
                                value="Un-Available"
                                name={`choices[${index}].Availability`}
                                inputProps={{ "aria-label": "Un-Available" }}
                              />
                              <ListItemText primary="Un-Available" />
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div>
                        <Button
                          outlineBlack
                          customClass={classes.copyBtn}
                          onClick={() => remove(index)}
                        >
                          <img src={reddelete} alt="Delete" /> Delete
                        </Button>
                      </div>
                    </div>
                    <Grid container spacing={1} sx={{ paddingBottom: "20px" }}>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <CustomInput
                          label="Choice Name"
                          type="text"
                          name={`choices[${index}].Choice`}
                          value={choice.Choice}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Enter Choice "
                          touched={touched.choices?.[index]?.Choice}
                          errors={errors.choices?.[index]?.Choice}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <CustomInput
                          label="Price"
                          type="text"
                          name={`choices[${index}].Price`}
                          value={choice.Price}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Enter Price "
                          touched={touched.choices?.[index]?.Price}
                          errors={errors.choices?.[index]?.Price}
                        />
                      </Grid>
                    </Grid>
                  </div>
                ))}
                <hr />
                <div className={classes.btncontainer}>
                  {ChoiceID && (
                    <>
                      <Button
                        fontsize="1.375rem"
                        customWidth="270px"
                        customClass={classes.delBtn}
                        onClick={() => handleDelete(ChoiceID)} // Open delete modal
                      >
                        <img src={deleteicon} alt="" />
                        Delete Choice
                      </Button>

                      {deleteId && (
                        <Delete
                          isOpen={openDelete}
                          onClose={handleClose}
                          handleConfirmDelete={handleConfirmDelete}
                          isDelete={isDelete}
                          confirmDelete={confirmDelete}
                          name="Choice Group"
                        />
                      )}
                    </>
                  )}

                  <Button
                    fontsize="1.375rem"
                    customWidth="426px"
                    customClass={classes.choiceBtn}
                    grey
                    shadowGrey
                    onClick={() =>
                      push({ Choice: "", Price: "", Availability: "Available" })
                    }
                  >
                    + Add Choice
                  </Button>

                  <Button
                    fontsize="1.375rem"
                    customWidth="426px"
                    customClass={classes.choiceBtn}
                    shadowOrange
                    onClick={handleSubmit} // Ensure validation checks occur
                  >
                    Create Choice
                  </Button>
                </div>
              </>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
};

export default CreateChoice;
