import React from "react";
import classes from "./settings.module.scss";
import Profile from "./profile";

const Settings = () => {
    return (
        <>
            <div className={classes.SettingHeading}>
                <h1>Settings</h1>
            </div>
            <div className={classes.wrapperContainer}>
                <Profile />
            </div>
        </>
    );
};

export default Settings;