import React, { useState, useRef } from "react";
import classes from "./editprofile.module.scss";
import profile_img from "../../../assets/images/profile_img.png";
import pencil_img from "../../../assets/images/pencil_img.png";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "../../../components/shared/customBtn";
import CustomInput from "../../../components/shared/customInput";
const EditProfile = ({
  setSignupData,
  setSignupFlow,
  signupFlow,
  signupData,
}) => {
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(profile_img);
  const fileInputRef = useRef(null);

  const editProfileSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter your email")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        "Invalid email"
      ),
    fname: Yup.string().required("Please enter your first name"),
    lname: Yup.string().required("Please enter your last name"),
  });
  const handleImageChange = (event, setFieldValue) => {
    setTimeout(() => {
      const file = event.target.files[0];
      console.log("file", file);
      if (file) {
        setSelectedImage(URL.createObjectURL(file));
        setFieldValue("file", file);
      }
    }, 1000);
  };
  return (
    <>
      <div className={classes.edirprofile}>
        <Formik
          initialValues={{ fname: "", lname: "", email: "", file: "" }}
          validationSchema={editProfileSchema}
          onSubmit={async (values) => {
            console.log("values:", values);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <>
              <div className={classes.ProfileImg}>
                <img
                  src={selectedImage}
                  alt="profile_img"
                  className={classes.profileImage}
                />
                <span>
                  <input
                    id="file"
                    name="file"
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      handleImageChange(event, setFieldValue)
                    }
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  {pencil_img && (
                    <img
                      src={pencil_img}
                      alt="pencil_img"
                      onClick={() => fileInputRef.current.click()}
                    />
                  )}
                </span>
              </div>

              <div className={classes.defaultForm}>
                <Form onSubmit={handleSubmit}>
                  {/* <div className={classes.inputMain}>
                                        <label>First Name</label>
                                        <Field
                                            type="text"
                                            name="fname"
                                            value={values.fname}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            className={`${classes.inputField} ${errors.fname && touched.fname ? classes.errorMsgLine : null
                                                }`}
                                            placeholder="enter first name"
                                        />
                                        <div className={classes.errorMsg}>
                                            <ErrorMessage name="fname" />
                                        </div>
                                    </div> */}
                  <CustomInput
                    label="First Name"
                    type="text"
                    name="fname"
                    value={values.fname}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="enter first name"
                    touched={touched.fname}
                    errors={errors.fname}
                  />
                  {/* <div className={classes.inputMain}>
                    <label>Last Name</label>
                    <Field
                      type="text"
                      name="lname"
                      value={values.lname}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={`${classes.inputField} ${
                        errors.lname && touched.lname
                          ? classes.errorMsgLine
                          : null
                      }`}
                      placeholder="enter first name"
                    />
                    <div className={classes.errorMsg}>
                      <ErrorMessage name="lname" />
                    </div>
                  </div> */}
                  <CustomInput
                    label="Last Name"
                    type="text"
                    name="lname"
                    value={values.lname}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="enter last name"
                    touched={touched.lname}
                    errors={errors.lname}
                  />
                  {/* <div className={classes.inputMain}>
                    <label>Email Address</label>
                    <Field
                      type="text"
                      name="email"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={`${classes.inputField} ${
                        errors.email && touched.email
                          ? classes.errorMsgLine
                          : null
                      }`}
                      placeholder="enter email"
                    />
                    <div className={classes.errorMsg}>
                      <ErrorMessage name="email" />
                    </div>
                  </div> */}
                   <CustomInput
                    label="Email Address"
                    type="text"
                    name="email"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="enter email"
                    touched={touched.email}
                    errors={errors.email}
                  />
                  <div>
                    <Button type="submiot" shadowOrange>
                      Save Changes!
                    </Button>
                  </div>
                </Form>
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EditProfile;
