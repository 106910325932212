import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import profileImg from "../../assets/images/icon.svg";
import acceptImg from "../../assets/images/accept.svg";
import rejectImg from "../../assets/images/reject.svg";
import detailImg from "../../assets/images/detail.svg";
import classes from "./liveorders.module.scss";
import "./liveordersBuiltIn.scss";
import lines from "../../assets/images/lines.svg";
import searchicon from "../../assets/images/searchicon.svg";
import { Select, ListItemText, FormControl, Radio } from "@mui/material";

const Orders = () => {
  const names = ["New Order", "Old Order", "Pending Order"];
  const [restaurantName, setRestaurantName] = useState(names[0]);
  const [selectedValue, setSelectedValue] = useState(names[0]);

  const handleChange = (event) => {
    setRestaurantName(event.target.value);
    setSelectedValue(event.target.value);
  };

  const ordersData = [
    { name: "James Decor", status: "New" },
    { name: "John Smith", status: "New" },
    { name: "Emily Brown", status: "New" },
    { name: "James Decor", status: "New" },
    { name: "John Smith", status: "New" },
    { name: "Emily Brown", status: "New" },
    { name: "James Decor", status: "New" },
    { name: "John Smith", status: "New" },
    { name: "Emily Brown", status: "New" },
    { name: "James Decor", status: "New" },
    { name: "John Smith", status: "New" },
    { name: "Emily Brown", status: "New" },
  ];

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const handleOpenDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handleCloseDropdown = () => {
    setOpenDropdownIndex(null);
  };

  return (
    <div className={classes.tablecontainer}>
      <div className={classes.topbar}>
        <div className={classes.drop}>
          <div className={`${classes.Dropdown} customdrop`}>
            <FormControl sx={{ minWidth: 100 }}>
              <Select
                labelId="radio-select-label"
                id="radio-select"
                value={restaurantName}
                onChange={handleChange}
                renderValue={(selected) => selected}
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name} className="StyleLiveOrder">
                    <Radio
                      checked={selectedValue === name}
                      value={name}
                      name="radio-buttons"
                      inputProps={{ "aria-label": name }}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={classes.topimg}>
          <img src={lines} alt="lines" />
          <img src={searchicon} alt="searchicon" />
        </div>
      </div>
      <ul className={classes.ordersList}>
        {ordersData.map((order, index) => (
          <li key={index} className={classes.orderItem}>
            <div className={classes.orderInfo}>
              <img
                src={profileImg}
                className={classes.profileImg}
                alt="profile"
              />
              <div className={classes.orderDetails}>
                <h4>{order.name}</h4>
                <p>
                  <span className={classes.dot}></span> {order.status}
                </p>
              </div>
            </div>
            <div className={classes.orderPrice}>
              <div className={classes.orderDetails}>
                <h3>$75.65</h3>
                <p>#AB02565</p>
              </div>
              <div className={classes.dropdownWrapper} id={`dropdown-${index}`}>
                <div
                  className={classes.verticalcircles}
                  onClick={() => handleOpenDropdown(index)}
                >
                  <div class={classes.circle}></div>
                  <div class={classes.circle}></div>
                  <div class={classes.circle}></div>
                </div>
                {openDropdownIndex === index && (
                  <div className={classes.customDropContent}>
                    <div
                      className={classes.dropdownItem}
                      onClick={handleCloseDropdown}
                      style={{ color: "#34A853" }}
                    >
                      <img src={acceptImg} alt="accept" />
                      <h5>Accept Order</h5>
                    </div>
                    <div
                      className={classes.dropdownItem}
                      onClick={handleCloseDropdown}
                      style={{ color: "#E21D25" }}
                    >
                      <img src={rejectImg} alt="reject" />
                      <h5>Reject Order</h5>
                    </div>

                    <div
                      className={classes.dropdownItem}
                      onClick={handleCloseDropdown}
                      style={{ color: "#777777" }}
                    >
                      <img src={detailImg} alt="detail" />
                      <h5>View Details</h5>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Orders;
