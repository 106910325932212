import React, { useState, useEffect, forwardRef } from "react";
import classes from "./Thankyou.module.scss";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import resetBanner from "../../../assets/images/thankyouBanner.png";
import thankyou from "../../../assets/images/thankyou.svg";



const Thankyou = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();



  return (
    <div className={classes.mainResetPassword}>
      <ToastContainer />
      <div className={classes.defaultForm}>
      <img src={thankyou} alt="reset banner" />
      <p>for creating your business! We will review it and get back to you shortly. You will be able to access the FOODIEES Business Accelerator Portal once a verification is approved.</p>
      </div>
      <div className={classes.rightBanner}>
      
        <img src={resetBanner} alt="reset banner" height="100%" />
      </div>
    </div>
  );
};

export default Thankyou;
