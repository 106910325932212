import React, { useState, useEffect, useRef } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import classes from "./SignUp.module.scss";
import { Link } from "react-router-dom";
import { userLogin } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { LOGIN } from "../../../constants";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import signupBanner from "../../../assets/images/signup2.png";
import logo from "../../../assets/images/logo.png";
import upload from "../../../assets/images/upload.png";
import step1 from "../../../assets/images/step1.svg";
import CustomInput from "../../../components/shared/customInput";
import LocationSelector from "../../../components/shared/customMap";

const infoSchema = Yup.object().shape({
  businessName: Yup.string().required("Please enter business name"),
  businessType: Yup.string().required("Please select business type"),
  businessCategory: Yup.string().required("Please select business category"),
  branches: Yup.string().required("Please enter branches"),
  phone: Yup.string().required("Please enter your phone"),
  file: Yup.mixed().required("Please select business logo"),
});
const BusinessInfo = ({
  setSignupData,
  setSignupFlow,
  signupFlow,
  signupData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      console.log("file", file);
      setPreview(URL.createObjectURL(file));
      setFieldValue("file", file);
    }
  };
  const [locationInfo, setLocationInfo] = useState();

  const handleLocationSelect = (locationInfo) => {
    console.log("Selected Location Info:", locationInfo);
  };
  return (
    <div className={classes.mainSignup}>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={{
            businessName: "",
            businessType: "",
            businessCategory: "",
            branches: "",
            phone: "",
            isPhoneSame: "",
            file: "",
            address: null,
          }}
          validationSchema={infoSchema}
          onSubmit={async (values) => {
            // setSignupData(values);
            setSignupFlow({
              ...signupFlow,
              businessInfo: false,
              legalInfo: true,
            });
            console.log("values:", values);
            // try {
            //   const res = await userLogin(values);
            //   if (res?.status == 200) {
            //     dispatch({
            //       type: LOGIN,
            //       payload: res.data,
            //     });
            //     localStorage.setItem("userData", JSON.stringify(res.data));
            //     setTimeout(() => {
            //       setLoading(false);
            //       navigate("/");
            //     }, 2000);
            //   }
            // } catch (error) {
            //   setErrorMsg(error.response.data.error.message);
            // }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formHeader}>
                <img src={step1} alt="stepper" className={classes.stepper} />
                <img src={logo} alt="logo" className={classes.signUpLogo} />
                <h1>
                  Set up your <span>Business!</span>
                </h1>
              </div>
              <Grid container spacing={1}>
                <Grid item lg={6} style={{ width: "100%" }}>
                  <CustomInput
                    label="Business Name"
                    type="text"
                    name="businessName"
                    value={values.businessName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="enter first name"
                    touched={touched.businessName}
                    errors={errors.businessName}
                  />
                </Grid>
                <Grid item lg={6} style={{ width: "100%" }}>
                  <CustomInput
                    label="Business Type"
                    type="select"
                    name="businessType"
                    value={values.businessType}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Select option"
                    touched={touched.businessType}
                    errors={errors.businessType}
                    options={["Restaurant", "Cafe", "Shop"]}
                  />
                </Grid>
                <Grid item lg={8} style={{ width: "100%" }}>
                  <CustomInput
                    label="Business Category"
                    type="select"
                    name="businessCategory"
                    value={values.businessCategory}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="enter first name"
                    touched={touched.businessCategory}
                    errors={errors.businessCategory}
                    options={["Restaurant", "Cafe", "Shop"]}
                  />
                </Grid>
                <Grid item lg={4} style={{ width: "100%" }}>
                  <CustomInput
                    label="Branches"
                    type="text"
                    name="branches"
                    value={values.branches}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="enter branches"
                    touched={touched.branches}
                    errors={errors.branches}
                  />
                </Grid>
              </Grid>
              <div>
              <CustomInput label="Select Business Address" />

              <div className={classes.mapStyleMain}>
               <LocationSelector
                  apiKey="AIzaSyB0gq-rFU2D-URzDgIQOkqa_fL6fBAz9qI"
                  onLocationSelect={handleLocationSelect}
                  showInput
                  locationInfo={locationInfo}
                  setLocationInfo={setLocationInfo}
                />
              </div>
              </div>
              <CustomInput
                label="Phone Number"
                type="text"
                name="phone"
                value={values.phone}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="enter phone number"
                touched={touched.phone}
                errors={errors.phone}
              />
              <CustomInput
                label="my business phone is the same as my mobile number."
                type="checkbox"
                name="isPhoneSame"
                value={values.isPhoneSame}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <div className={classes.uploadMain}>
                <label>Upload Logo</label>

                <input
                  id="file"
                  name="file"
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={(event) => handleImageChange(event, setFieldValue)}
                />
                <div
                  onClick={() => fileInputRef.current.click()}
                  className={classes.uploadField}
                >
                  {preview ? (
                    <>
                      <img
                        src={preview}
                        alt="Uploaded"
                        style={{ width: "100px" }}
                      />{" "}
                      <br />
                    </>
                  ) : (
                    <img
                      src={upload}
                      alt="Placeholder"
                      style={{ cursor: "pointer" }}
                    />
                  )}

                  <p>
                    {" "}
                    <span>Upload</span> png, jpg <br />
                    up to 1mb only
                  </p>
                </div>
                <div className={classes.errorMsg}>
                  <ErrorMessage name="file" />
                </div>
              </div>

              <button type="submit">
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  "Next"
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div className={classes.rightBanner}>
        <div className={classes.bannerHeading}>
          <div className={classes.bannerHeadingeee}>
            Increase sales <br /> with Foodie!
          </div>
        </div>
        <img src={signupBanner} alt="login banner" height="100%" />
      </div>
    </div>
  );
};

export default BusinessInfo;
