import React, { useState, useEffect } from "react";
import ReusableTable from "../../components/shared/resuabletable";
import Delete from "../../components/shared/deleteModal";
import classes from "./manageRole.module.scss";
import "./manageRoleBuiltin.scss";
import AddRole from "./AddNewRole";
import TableFilter from "../../components/shared/tableFilter";

const ManageRoles = () => {
  const [roles, setRoles] = useState([]);
  const [rolesDeep, setRolesDeep] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [error, setError] = useState(null);
  const [addRole, setAddRole] = useState(false);

  useEffect(() => {
    const handleData = async () => {
      try {
        const response = await fetch(
          "https://jsonplaceholder.typicode.com/comments?_limit=101"
        );
        const data = await response.json();
        setRoles(data);
        setRolesDeep(data);
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      }
    };
    handleData();
  }, []);

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
  };

  useEffect(() => {
    if (searchTerm.length > 2) {
      const filteredRoles = rolesDeep.filter((role) =>
        role?.name?.toLowerCase().includes(searchTerm)
      );
      setRoles(filteredRoles);
    } else {
      setRoles(rolesDeep);
    }
  }, [searchTerm, rolesDeep]);

  const columns = [
    {
      label: "#",
      field: "index",
    },
    {
      label: "Created at",
      field: "name",
    },
    {
      label: "Type",
      field: "email",
    },
    {
      label: "Description",
      field: "body",
    },
  ];

  const handleClose = () => {
    setOpenDelete(false);
    setAddRole(false);
    setConfirmDelete(false);
  };

  const handleDelete = (id) => {
    console.log("row-id:", id);
    setOpenDelete(true);
    setIsDelete(true);
    setDeleteId(id);
  };

  const handleConfirmDelete = () => {
    console.log("delete success", deleteId);
    setIsDelete(false);
    setConfirmDelete(true);
  };

  const handleEdit = (id) => {
    console.log("edit role id:", id);
    setAddRole(true); // Opens the AddRole modal
  };

  const addRoleHandle = () => {
    setAddRole(true);
  };

  return (
    <div className={classes.table_section}>
      <div className={classes.headerContainerCustomerRole}>
        <TableFilter
          heading="Role"
          tableLength={roles.length}
          searchPlaceholder="Search in Role"
          handleSearch={handleSearch}
          handleModalOpenLeft={addRoleHandle}
          buttonTextLeft="Add New Role"
          iconBtnLeft
          datePicker
        />
      </div>
      <div>
        <ReusableTable
          columns={columns}
          data={roles}
          dataDeep={rolesDeep}
          handleDelete={handleDelete}
          searchTerm={searchTerm}
          pagination
          rowPerPage={11}
          handleEdit={handleEdit}
          actionButton
        />
      </div>
      {deleteId && (
        <Delete
          isOpen={openDelete}
          onClose={handleClose}
          handleConfirmDelete={handleConfirmDelete}
          isDelete={isDelete}
          confirmDelete={confirmDelete}
          name="Role"
        />
      )}
      {addRole && (
        <AddRole
          isOpen={addRole}
          onClose={handleClose}
          addRoleClose={setAddRole}
        />
      )}
    </div>
  );
};

export default ManageRoles;
