import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import classes from "./header.module.scss";
import "./headerBuiltin.scss";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../../constants";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import logo from "../../assets/images/logo.png";
import bell from "../../assets/images/bell.svg";
import fork from "../../assets/images/fork.svg";
import boy from "../../assets/images/boy.svg";
import profileIcon from "../../assets/images/profileIcon.svg";
import man from "../../assets/images/man.svg";
import logoutIcon from "../../assets/images/logoutIcon.svg";

const Header = ({ handleArrow }) => {
  const names = ["Foodie Gulberg", "Foodie Johar Town", "Foodie Askari 11"];
  const notifications = [
    { text: "LOREAM IPSUM TEXT HERE", date: "7 August 2024 - 02:29 PM" },
    { text: "LOREAM IPSUM TEXT HERE", date: "7 August 2024 - 02:29 PM" },
    { text: "LOREAM IPSUM TEXT HERE", date: "7 August 2024 - 02:29 PM" },
    { text: "LOREAM IPSUM TEXT HERE", date: "7 August 2024 - 02:29 PM" },
  ];
  

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlelogout = () => {
    dispatch({
      type: LOGOUT,
    });
    navigate("/sign-in");
    localStorage.removeItem("userData");
  };

  const [restaurantName, setRestaurantName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRestaurantName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [showDrop, setShowDrop] = useState(false);
  const [showBellDrop, setShowBellDrop] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);

  const handleDrop = () => {
    if (showBellDrop) {
      setShowBellDrop(false);
    }
    setShowDrop((prev) => !prev);
  };

  const handleBellDrop = () => {
    if (showDrop) {
      setShowDrop(false);
    }
    setShowBellDrop((prev) => !prev);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleDrop();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
  }, []);
  const handleLogout = () => {
    navigate("./sign-in");
    localStorage.removeItem("authToken");
    localStorage.removeItem("userRole");
    console.log("logout");
    setShowDrop(false);
  };
  const hanldleProfile = () => {
    navigate("/settings");
    setShowDrop(false);
  };

  return (
    <nav className={classes.headerNav}>
      {/* <div className={classes.logo}>
        <img src={logo} alt="logo" />
        <div className={classes.arrow} onClick={handleArrow}>→</div>
      </div> */}
      <div className={classes.navLinks}>
        <div className={classes.belldrop}>
          <div className={classes.notification} onClick={handleBellDrop}>
            <span>3</span> <img src={bell} alt="logo" />
          </div>
          {showBellDrop && (
           <div className={classes.addBellDropInner} ref={dropdownRef2}>
           <ul>
             {notifications.map((notification, index) => (
               <li key={index}>
                 <img src={man} alt="" />
                 <div className={classes.textContainer}>
                   <span>{notification.text}</span>
                   <p>{notification.date}</p>
                 </div>
               </li>
             ))}
           </ul>
         </div>
         
          )}
        </div>

        <div className={`${classes.selectDropdownCust} mainBuiltinStyle`}>
          <div className={`${classes.top_drop}`}>
            <div className={classes.drop_container} onClick={handleDrop}>
              <div className={classes.desc1}>
                <h5>Hassan Malik</h5>
                <h6>Product Designer</h6>
              </div>
              <div className={classes.desc2}>
                <img src={boy} alt="Uploaded" />
              </div>
            </div>
            {showDrop && (
              <div className={classes.addNewDropInner} ref={dropdownRef}>
                <ul>
                  <li onClick={hanldleProfile}>
                    {" "}
                    <img src={profileIcon} alt="" /> Profile
                  </li>
                  <li onClick={handleLogout}>
                    {" "}
                    <img src={logoutIcon} alt="" /> Logout
                  </li>
                </ul>
              </div>
            )}
          </div>
          {/* <FormControl sx={{ m: 1, minWidth: 50 }}>
            <Select 
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              displayEmpty
              multiple
              value={restaurantName}
              onChange={handleChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select Option</em>;
                }
                if (selected.length === 1) {
                  return selected[0];
                }
                return `${selected[0]}, ...`;
              }}
              startAdornment={<InputAdornment position="start">
              <img
                  src={fork}
                  alt="fork"
                  style={{
                    marginRight: "8px",
                    width: "20px",
                    height: "20px",
                  }}
                />
            </InputAdornment>}
            >
               
                  <MenuItem disabled value="" className="selectBuiltinStylePlaceholder">
                    <em>Select Option</em>
                  </MenuItem>
               {names.map((name) => (
                  <MenuItem key={name} value={name} className="selectBuiltinStyleHeader">
                    <Checkbox checked={restaurantName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
                <div className={classes.dropLink}>
                  <Link to="#">None</Link>
                  <Link to="#">All</Link>
                  </div>
            </Select>
          </FormControl> */}
        </div>
      </div>

      {/* <Box sx={{cursor:"pointer"}} onClick={handlelogout}>Logout</Box> */}
    </nav>
  );
};

export default Header;
