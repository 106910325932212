// import React from 'react';
// import { Navigate } from 'react-router-dom';

// const ProtectedRoute = ({ children }) => {
//     const isAuthenticated = !!localStorage.getItem('authToken'); // Check for auth token or any other auth logic

//     if (!isAuthenticated) {
//         return <Navigate to="/sign-in" />;
//     }

//     return children;
// };

// export default ProtectedRoute;

// import React from 'react';
// import { Navigate } from 'react-router-dom';

// const ProtectedRoute = ({ children, requiredRole }) => {
//     const isAuthenticated = !!localStorage.getItem('authToken'); // Check for auth token or any other auth logic
//     const userRole = localStorage.getItem('userRole'); // Assume userRole is stored in localStorage

//     if (!isAuthenticated) {
//         return <Navigate to="/sign-in" />;
//     }

//     if (requiredRole && !requiredRole.includes(userRole)) {
//         return <Navigate to="/unauthorized" />; // Redirect to an unauthorized page or another route
//     }

//     return children;
// };

// export default ProtectedRoute;


import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children, requiredRole }) => {
    const isAuthenticated = !!localStorage.getItem('authToken'); // Check for auth token
    const userRole = localStorage.getItem('userRole'); // Assume userRole is stored in localStorage
    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to="/sign-in" />;
    }

    if (requiredRole && !requiredRole.includes(userRole)) {
        return <Navigate to="/unauthorized" />;
    }

    // Redirect based on user role
    // if (location.pathname == '/' || location.pathname == '/dashboard') {
    //     if (userRole === 'admin') {
    //         return <Navigate to="/dashboard" />;
    //     } else if (userRole === 'moderator') {
    //         return <Navigate to="/menu-management" />;
    //     } else if (userRole === 'user') {
    //         return <Navigate to="/settings" />;
    //     }
    // }

    return children;
};

export default ProtectedRoute;
