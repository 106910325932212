import React from "react";
import classes from "./dashboard.module.scss";
import "./dashboardBuiltin.scss";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Stack from "@mui/material/Stack";
import { PieChart } from "@mui/x-charts";
import { LinearProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
const BorderLinearProgress = styled(LinearProgress)(({ theme, color }) => ({
  height: 15,
  borderRadius: 10,
  border: "1px solid #d3d3d3", // Custom border
  [`&.${LinearProgress.colorPrimary}`]: {
    backgroundColor: "red",
  },
  [`& .${LinearProgress.bar}`]: {
    borderRadius: 10,
    backgroundColor: color || "blue",
  },
}));
const OrderSummary = () => {
  const navigate = useNavigate();
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  const chartData = [
    { label: "Hardee’s Gulberg", value: 9348319 },
    { label: "Taste of Lahore-Wembley", value: 8348319 },
    { label: "Taste of Lahore-Hounslow", value: 3348319 },
  ];
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOrders = () => {
    navigate("/orders/live-orders");
  };

  return (
    <div className={classes.OrderSummaryWrap}>
      <div className={classes.textWrap}>
        <div className={classes.textHolder}>
          <h3>Orders Summary</h3>
          <p>Lorem ipsum dolor sit amet, consectetur</p>
          <div className="barChart"></div>
        </div>
        <div className="tabsWrap">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Monthly" {...a11yProps(0)} />
                <Tab label="Weekly" {...a11yProps(1)} />
                <Tab label="Today" {...a11yProps(2)} />
              </Tabs>
            </Box>
          </Box>
        </div>
      </div>
      <div className={classes.newOrderContainer}>
        <div className={classes.orderInfo}>
          <div className={classes.leftOrderInfo}>
            <div className={classes.count}>25</div>
            <p>New Orders</p>
            <div className={classes.greenDot}></div>
          </div>
          <div className={classes.rightOrderInfo} onClick={handleOrders}>
            <div className={classes.manageOrder}>Manage Orders</div>
            <div className={classes.rightArrowIcon}>
              <KeyboardArrowRightIcon />
            </div>
          </div>
        </div>
      </div>
      {/* <Grid lg={6}> */}
      <Grid container spacing={3}>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <div className={classes.menuBox}>
            <div className={classes.menuText}>
              <h2>25</h2>
              <p>On Delivery</p>
            </div>
            <div className={classes.menuIcon}></div>
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <div className={classes.menuBox}>
            <div className={classes.menuText}>
              <h2>35</h2>
              <p>Delivered</p>
            </div>
            <div className={classes.menuIcon}></div>
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <div className={classes.menuBox}>
            <div className={classes.menuText}>
              <h2>5</h2>
              <p>Canceled</p>
            </div>
            <div className={classes.menuIcon}></div>
          </div>
        </Grid>
        {/* </Grid> */}
      </Grid>
      <div className={classes.saleContainer}>
        <div className={`${classes.divide} customPieDashboard`}>
          <Stack direction="row">
            <PieChart
              series={[
                {
                  // paddingAngle: 5,
                  innerRadius: 60,
                  outerRadius: 80,
                  data: chartData,
                  // cornerRadius: 12,
                },
              ]}
              width={255}
              height={255}
              legend={{ hidden: true }}
            />
          </Stack>
        </div>
        <div className={`${classes.info} progressBar`}>
          <Box
            minWidth="220px"
            mr={1}
            mb={2}
            className={`${classes.extra} progressBarOne`}
          >
            <div className="leftText">On Delivery (15%)</div>
            <BorderLinearProgress
              variant="determinate"
              value={80}
              className={classes.borderLinear}
            />
            <div className="rightText">25</div>
            {/* <Box minWidth={35}></Box> */}
          </Box>

          <Box
            minWidth="220px"
            mr={1}
            mb={2}
            className={`${classes.extra} progressBarSecond`}
          >
            <div className="leftText">Delivered (15%)</div>
            <BorderLinearProgress variant="determinate" value={60} />
            <div className="rightText">60</div>
          </Box>
          <Box
            minWidth="220px"
            mr={1}
            className={`${classes.extra} progressBarThird`}
          >
            <div className="leftText">Cancelled (15%)</div>
            <BorderLinearProgress variant="determinate" value={40} />
            <div className="rightText">7</div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
