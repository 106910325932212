import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import "./customLoader.scss"

const CustomLoader = ({ size, thickness, colors, message, variant }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <CircularProgress 
        size={size} 
        thickness={thickness} 
        sx={{ color: colors ? colors : "#F57E2A" }} 
        variant={variant} 
      />
      {message && <Box mt={2}>{message}</Box>}
    </Box>
  );
};

CustomLoader.propTypes = {
  size: PropTypes.number,
  thickness: PropTypes.number,
  color: PropTypes.string,
  message: PropTypes.string,
  variant: PropTypes.oneOf(['determinate', 'indeterminate', 'static']),
};

CustomLoader.defaultProps = {
  size: 40,
  thickness: 3.6,
  color: 'primary',  // This should be a valid CSS color or a theme color
  message: '',
  variant: 'indeterminate',
};

export default CustomLoader;
