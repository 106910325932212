import React, { useState, useEffect } from "react";
import ReusableTable from "../../components/shared/resuabletable";
import classes from "./managecustomer.module.scss";
import "./managecustomerBuiltin.scss";
import Delete from "../../components/shared/deleteModal";
import TableFilter from "../../components/shared/tableFilter";

const CustomerManagement = () => {
  const [customers, setCustomers] = useState([]);
  const [customersDeep, setCustomersDeep] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleData = async () => {
      try {
        const rest = await fetch(
          "https://jsonplaceholder.typicode.com/comments"
        );
        const data = await rest.json();
        setCustomers(data);
        setCustomersDeep(data);
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      }
    };
    handleData();
  }, []);

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
  };

  useEffect(() => {
    if (searchTerm.length > 2) {
      const filteredCustomers = customersDeep.filter((customer) =>
        customer?.name?.toLowerCase().includes(searchTerm)
      );
      setCustomers(filteredCustomers);
    } else {
      setCustomers(customersDeep);
    }
  }, [searchTerm, customersDeep]);

  const columns = [
    { label: "#", field: "index" },
    { label: "Customer Name", field: "name" },
    { label: "Email", field: "email" },
    { label: "Phone Number", field: "body" },
  ];

  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
  };
  const handleDelete = (id) => {
    console.log("row-id:", id);
    setOpenDelete(true);
    setIsDelete(true);
    setDeleteId(id);
  };
  const handleConfirmDelete = () => {
    console.log("delete success", deleteId);
    setIsDelete(false);
    setConfirmDelete(true);
  };

  return (
    <div className={classes.table_section}>
      <div className={classes.headerContainerManageCustomer}>
        <TableFilter
          heading="Manage Customers"
          tableLength={customers.length}
          searchPlaceholder="Search in Customer"
          handleSearch={handleSearch}
          datePicker
        />
      </div>
      <ReusableTable
        columns={columns}
        data={customers}
        searchTerm={searchTerm}
        pagination
        rowPerPage={10}
        handleDelete={handleDelete}
        actionButton
      />
      {deleteId && (
        <Delete
          isOpen={openDelete}
          onClose={handleClose}
          handleConfirmDelete={handleConfirmDelete}
          isDelete={isDelete}
          confirmDelete={confirmDelete}
          name="Customer"
        />
      )}
    </div>
  );
};

export default CustomerManagement;
