import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./liveorders.module.scss";
import order from "../../assets/images/order.svg";
import rider from "../../assets/images/rider.svg";
import dateIcon from "../../assets/images/date.svg";
import photo from "../../assets/images/photo.svg";
import cash from "../../assets/images/cash.svg";
import search from "../../assets/images/search.svg";
import "./liveordersBuiltIn.scss";
import Button from "../../components/shared/customBtn";
import karahi from "../../assets/images/karahi.svg";
import shawarma from "../../assets/images/shawarma.svg";
import naan from "../../assets/images/naan.svg";
import tikka from "../../assets/images/tikka.svg";
import burger from "../../assets/images/burger.svg";

const Summary = ({ OpenModal, openCancelModal }) => {
  const style = {
    borderBottom: "none !important",
  };

  const customers = [
    {
      Name: "Chicken Karchi",
      From: "From $8.62",
      amount: "$50",
    },
    {
      Name: "Paratha Roll",
      From: "From $8.62",
      amount: "$75",
    },
    {
      Name: "Fresh Naan",
      From: "From $8.62",
      amount: "$100.45",
    },
    {
      Name: "Chicken BBQ",
      From: "From $8.62",
      amount: "$100.31",
    },
    {
      Name: "Chicken Burger",
      From: "From $8.62",
      amount: "$100",
    },
  ];

  const totalAmount = customers.reduce(
    (acc, customer) => acc + parseFloat(customer.amount.replace("$", "")),
    0
  );

  return (
    <div className={`${classes.mainTab} customstyle`}>
      <div className={classes.header}>
        <h4>Order Summary</h4>
        <div className={classes.iconContainer}>
          <div className={classes.squareButton} onClick={OpenModal}>
            <img src={search} alt="Search" />
          </div>
          <div className={classes.circleButton}>
            <CloseIcon fontSize="small" />
          </div>
        </div>
      </div>
      <hr />

      <div className={classes.orderDetails}>
        <div className={classes.orderInfo}>
          <div className={classes.orderHeader}>
            <div className={classes.orderTitle}>
              <h4>Item Order</h4>
              <span>#AB02565</span>
            </div>
            <div className={classes.orderActions}>
              <Button
                customClass={classes.btn1}
                outline
                onClick={openCancelModal}
              >
                Cancel
              </Button>

              <Button customClass={classes.btn2}>Accept</Button>
            </div>
          </div>

          <div className={classes.orderStatus}>
            <Button customClass={classes.btn3}>
              <span className={classes.dot}></span> New
            </Button>

            <Button customClass={classes.btn4} black>
              <img src={cash} alt="Cash" /> Cash
            </Button>
          </div>

          <div className={classes.orderMeta}>
            <div className={classes.metaItem1}>
              <img src={dateIcon} alt="Date" />
              <div className={classes.metaText}>
                <h6>Order Date</h6>
                <p>27, October, 2023</p>
              </div>
            </div>
            <div className={classes.metaItem}>
              <img src={rider} alt="Rider" />
              <div className={classes.metaText}>
                <h6>Estimated Delivery Time</h6>
                <p>12:45</p>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.billingInfo}>
          <div className={classes.billingDetails}>
            <p>Billing to</p>
            <img src={photo} alt="Photo" />
            <h5>James Decor</h5>
            <p>2756 Mapview Drive South</p>
            <p>Fulton Tennessee - 38257</p>
          </div>
        </div>
      </div>

      <div className={`${classes.orderTable} od`}>
        <Table className={classes.tablecontent}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.columnHeader}>
                Items & Description
              </TableCell>
              <TableCell className={classes.columnHeader}>Status</TableCell>
              <TableCell className={classes.columnHeader}>Shipped</TableCell>
              <TableCell className={classes.columnHeader}>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer, index) => (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell sx={style} className={classes.cell}>
                  <div className={classes.content}>
                    <div className={classes.imageContainer}>
                      <div className={classes.countCircle}>{index + 1}</div>
                      {index === 0 && (
                        <div className={classes.imgconatiner}>
                          <img
                            src={karahi}
                            className={classes.dishimg}
                            alt={`Dish 1`}
                          />
                        </div>
                      )}

                      {index === 1 && (
                        <div className={classes.imgconatiner}>
                          <img
                            src={shawarma}
                            className={classes.dishimg}
                            alt={`Dish 1`}
                          />
                        </div>
                      )}
                      {index === 2 && (
                        <div className={classes.imgconatiner}>
                          <img
                            src={naan}
                            className={classes.dishimg}
                            alt={`Dish 1`}
                          />
                        </div>
                      )}
                      {index === 3 && (
                        <div className={classes.imgconatiner}>
                          <img
                            src={tikka}
                            className={classes.dishimg}
                            alt={`Dish 1`}
                          />
                        </div>
                      )}
                      {index === 4 && (
                        <div className={classes.imgconatiner}>
                          <img
                            src={burger}
                            className={classes.dishimg}
                            alt={`Dish 1`}
                          />
                        </div>
                      )}
                    </div>

                    <div className={classes.detail}>
                      <h5>{customer.Name}</h5>
                      <h6>
                        <span className={classes.bold}>Category:</span> Main
                        Course Chicken Dishes
                      </h6>
                      <p>
                        From <span className={classes.bold}>$8.62</span>
                      </p>
                    </div>
                  </div>
                </TableCell>
                <TableCell sx={style} className={classes.cell}>
                  <img src={order} alt="Order" />
                </TableCell>
                <TableCell sx={style} className={classes.cell}>
                  <img src={order} alt="Order" />
                </TableCell>
                <TableCell sx={style} className={classes.cell}>
                  {customer.amount}
                </TableCell>
              </TableRow>
            ))}

            <TableRow className="divider">
              <TableCell colSpan={4}>
                <hr />
              </TableCell>
            </TableRow>
            <TableRow className="secondlast">
              <TableCell colSpan={3} className={classes.totalLabel}>
                Total
              </TableCell>
              <TableCell className={classes.totalAmount}>
                ${totalAmount}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} align="center">
                <Button customClass={classes.btn5} shadowOrange>
                  Mark as Complete
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Summary;
