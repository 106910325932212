import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Foodimage from "../../assets/images/foodimage.png";
import CustomerImage from "../../assets/images/avatar.png";
import classess from "./CustomerReview.module.scss";
import StarIcon from "@mui/icons-material/Star";
import "./CustomerReviewBuiltin.scss";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import classes from "./CustomerReview.module.scss";
const SlickSlider = () => {
  const settings = {
    className: "slider variable-width",
    infinite: true,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      // {
      //   breakpoint: 5000,
      //   settings: {
      //     slidesToShow: 4,
      //     slidesToScroll: 1,
      //     infinite: true,
      //   },
      // },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const reviews = [
    {
      title: "Ramadan Deal 1",
      subheader: "Lorem ipsum is simply dummy text of the printing",
      reviewText: `We recently had dinner with friends at David CC and we all walked away
          with a great experience. Good food, pleasant environment, personal
          attention through all the evening. Thanks to the team and we will be
          back!`,
      customerName: "Tina Haris",
      rating: 4.2,
      foodImage: Foodimage,
      customerImage: CustomerImage,
    },
    {
      title: "Ramadan Deal 1",
      subheader: "Lorem ipsum is simply dummy text of the printing",
      reviewText: `We recently had dinner with friends at David CC and we all walked away
          with a great experience. Good food, pleasant environment, personal
          attention through all the evening. Thanks to the team and we will be
          back!`,
      customerName: "Tina Haris",
      rating: 4.2,
      foodImage: Foodimage,
      customerImage: CustomerImage,
    },
    {
      title: "Ramadan Deal 1",
      subheader: "Lorem ipsum is simply dummy text of the printing",
      reviewText: `We recently had dinner with friends at David CC and we all walked away
          with a great experience. Good food, pleasant environment, personal
          attention through all the evening. Thanks to the team and we will be
          back!`,
      customerName: "Tina Haris",
      rating: 4.2,
      foodImage: Foodimage,
      customerImage: CustomerImage,
    },
    {
      title: "Ramadan Deal 1",
      subheader: "Lorem ipsum is simply dummy text of the printing",
      reviewText: `We recently had dinner with friends at David CC and we all walked away
          with a great experience. Good food, pleasant environment, personal
          attention through all the evening. Thanks to the team and we will be
          back!`,
      customerName: "Tina Haris",
      rating: 4.2,
      foodImage: Foodimage,
      customerImage: CustomerImage,
    },
    {
      title: "Ramadan Deal 1",
      subheader: "Lorem ipsum is simply dummy text of the printing",
      reviewText: `We recently had dinner with friends at David CC and we all walked away
          with a great experience. Good food, pleasant environment, personal
          attention through all the evening. Thanks to the team and we will be
          back!`,
      customerName: "Tina Haris",
      rating: 4.2,
      foodImage: Foodimage,
      customerImage: CustomerImage,
    },
  ];

  return (
    <div className={`${classess.sliderContainer} parentSlider parentCard`}>
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <Card key={index}>
            <CardHeader
              avatar={<Avatar src={review.foodImage}></Avatar>}
              title={review.title}
              subheader={review.subheader}
            />
            <CardContent>
              <Typography>{review.reviewText}</Typography>
            </CardContent>
            <CardContent>
              <div className={classes.parentFooter}>
                <div className={classes.footerLeft}>
                  <img
                    src={review.customerImage}
                    alt={`${review.customerName} image`}
                  />
                  <Typography>{review.customerName}</Typography>
                </div>
                <div className={classes.footerRight}>
                  <StarIcon />
                  <Typography className={classes.ratingIcon}>
                    {review.rating}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </Slider>
    </div>
  );
};

export default SlickSlider;
