import React, { useState, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Typography, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "../../components/shared/customBtn";
import classes from "./manageUser.module.scss";
import profile_img from "../../assets/images/placeholderImage.png";
import camera_image from "../../assets/images/Camera.png";
import CustomInput from "../../components/shared/customInput";
const ChangePass = ({ handleClose, onSubmit, headTitle, textButton }) => {
  const [userImage, setUserImage] = useState(profile_img);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const fileInputRef = useRef(null);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    roles: Yup.array().min(1, "Role is required").required("Role is required"),
    store: Yup.string().required("Store is required"),
  });

  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      setUserImage(URL.createObjectURL(file));
      setFieldValue("file", file);
    }
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPass = () => setShowConfirmPass(!showConfirmPass);

  return (
    <Formik
      initialValues={{
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        roles: [],
        store: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
      }) => (
        <Form className={classes.parentModal}>
          <div className={classes.header}>
            <Typography className={classes.title}>{headTitle}</Typography>
            <hr />
          </div>

          <div className={classes.ProfileImg}>
            <img
              src={userImage}
              alt="profile_img"
              className={classes.profileImage}
            />
            <span>
              <input
                id="file"
                name="file"
                type="file"
                accept="image/*"
                onChange={(event) => handleImageChange(event, setFieldValue)}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <img
                src={camera_image}
                alt="camera_image"
                onClick={() => fileInputRef.current.click()}
              />
            </span>
          </div>

          <div className={classes.userFormHolder}>
            <CustomInput
              label="Enter Password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="enter password"
              touched={touched.password}
              errors={errors.password}
              eyeIcon
              setPasswordChange={setShowPassword}
              passwordChange={showPassword}
            />
            <CustomInput
              label="Confirm Password"
              type={showConfirmPass ? "text" : "password"}
              name="confirmPassword"
              value={values.confirmPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="enter confirm password"
              touched={touched.confirmPassword}
              errors={errors.confirmPassword}
              eyeIcon
              setPasswordChange={setShowConfirmPass}
              passwordChange={showConfirmPass}
            />
          </div>

          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              customClass={classes.btnAddUsers}
              shadowOrange
              fontsize="0.75rem"
              customWidth="350px"
            >
              {textButton}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const PasswordField = ({
  name,
  label,
  value,
  showPassword,
  toggleShowPassword,
  handleChange,
  handleBlur,
  error,
  touched,
}) => (
  <div className={classes.inputMain}>
    <label>{label}</label>
    <div className={classes.eyeBox}>
      <Field
        type={showPassword ? "text" : "password"}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`${classes.inputField} ${
          error && touched ? classes.errorMsgLine : ""
        }`}
        placeholder={label}
      />
      <IconButton onClick={toggleShowPassword} className={classes.eyeIcon}>
        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
    </div>
    {error && touched && (
      <div className={classes.errorMsg}>
        <ErrorMessage name={name} />
      </div>
    )}
  </div>
);

export default ChangePass;
