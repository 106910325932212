import React, { useState, useEffect, forwardRef } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import classes from "./SignUp.module.scss";
import { Link } from "react-router-dom";
import { userLogin } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { LOGIN } from "../../../constants";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import signupBanner from "../../../assets/images/signup1.png";
import logo from "../../../assets/images/logo.png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";
import CustomInput from "../../../components/shared/customInput";

const signUpSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email"
    ),
  first_name: Yup.string().required("Please enter your first name"),
  last_name: Yup.string().required("Please enter your last name"),
  phone: Yup.string().required("Please enter your phone number"),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});
const ProfileInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [signupData, setSignupData] = useState();

  return (
    <div className={classes.mainSignup}>
      <ToastContainer />
      <div className={classes.defaultForm}>
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            phone: "",
          }}
          validationSchema={signUpSchema}
          onSubmit={async (values) => {
           
            setSignupData(values);
            navigate("/verify-register-otp")
            const name = {
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              password: values.password,
              phone: values.phone,
            }

            console.log("values:", name);
            // try {
            //   const res = await userLogin(values);
            //   if (res?.status == 200) {
            //     dispatch({
            //       type: LOGIN,
            //       payload: res.data,
            //     });
            //     localStorage.setItem("userData", JSON.stringify(res.data));
            //     setTimeout(() => {
            //       setLoading(false);
            //       navigate("/");
            //     }, 2000);
            //   }
            // } catch (error) {
            //   setErrorMsg(error.response.data.error.message);
            // }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formHeader}>
                <img src={logo} alt="logo" className={classes.signUpLogo} />
                <h3>Are you prepared to expand your Business?</h3>
              </div>
              <div className={classes.gridBox}>
                <Grid container spacing={2}>
                  <Grid item lg={6} style={{ width: "100%" }}>
                    <CustomInput
                      label="First Name"
                      type="text"
                      name="first_name"
                      value={values.first_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="enter first name"
                      touched={touched.first_name}
                      errors={errors.first_name}
                    />
                  </Grid>
                  <Grid item lg={6} style={{ width: "100%" }}>
                    <CustomInput
                      label="Last Name"
                      type="text"
                      name="last_name"
                      value={values.last_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="enter last name"
                      touched={touched.last_name}
                      errors={errors.last_name}
                    />
                  </Grid>
                </Grid>
              </div>
              <CustomInput
                label="Email Address"
                type="text"
                name="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="enter email"
                touched={touched.email}
                errors={errors.email}
              />
              <CustomInput
                label="Create Password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="enter password"
                touched={touched.password}
                errors={errors.password}
                eyeIcon
                setPasswordChange={setShowPassword}
                passwordChange={showPassword}
              />
              <CustomInput
                label="Confirm Password"
                type={confirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={values.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="confirm password"
                touched={touched.confirmPassword}
                errors={errors.confirmPassword}
                eyeIcon
                setPasswordChange={setConfirmPassword}
                passwordChange={confirmPassword}
              />
              <CustomInput
                label="Phone Number"
                type="text"
                name="phone"
                value={values.phone}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="enter phone number"
                touched={touched.phone}
                errors={errors.phone}
              />

              <button type="submit">
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  "Get Started"
                )}
              </button>
              <div className={classes.signupLink}>
                <div className={classes.signupRow}>
                  <span>Already have an account? </span>
                  <Link to="/sign-in" className={classes.linkSignup}>
                    Sign In
                  </Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className={classes.rightBanner}>
        <div className={classes.bannerHeading}>
          Increase sales <br /> with Foodie!
        </div>
        <img src={signupBanner} alt="login banner" height="100%" />
      </div>
    </div>
  );
};

export default ProfileInfo;
// className={classes.selectField}
