import React, { useState } from "react";
import classes from "./customization.module.scss";
import logo from "../../../assets/images/logo.png";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
    FormControl,
    Select,
    MenuItem,
    OutlinedInput,
    Radio,
    ListItemText,
} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import edit_img from '../../../assets/images/edit_img.png'
import Arrow_Down from '../../../assets/images/Arrow-down.png'
import change_img from '../../../assets/images/change_img.png'
import delete_img from '../../../assets/images/delete_img.png'
import checked_img from '../../../assets/images/checked.png'
import './customizationBuiltin.scss'
import { ArrowRight } from "@mui/icons-material";
import Button from "../../../components/shared/customBtn";

const Customization = () => {
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = React.useState('');
    const [showOptions, setShowOptions] = useState(false);
    const [restaurantName, setRestaurantName] = useState([]);
    const [selectedColor, setSelectedColor] = useState("");
    const [selectedTheme, setSelectedTheme] = useState("");
    const names = [
        'Light Mode',
        'Dark Mode',
    ];
    const handleChange = (event) => {
        setSelectedTheme(event.target.value);
        const {
            target: { value },
        } = event;
        setRestaurantName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };
    const colors = ["#F57E2A", "#E50001", "#3392CC", "#ff0000", "#836529", "#EE3A46", "#E4042C", "#0E79BF", "#DE5B9E", "#C6164F"];
    const handleCheckboxChange = (event) => {
        setSelectedColor(event.target.value);
    };
    console.log("selectedColor:", selectedColor)

    return (
        <>
            <div className={classes.customizationMainContainer}>
                <div className={classes.CustomizationContainer}>
                    <div className={classes.logoImg}>
                        <img src={logo} alt="logo" />
                    </div>
                    <h1>Website Logo</h1>
                    <p>you can upload upto 2MB, Image (JPG, PNG)</p>
                    <div className={classes.change}>
                        <div className={classes.changeBtns} onClick={toggleOptions}>
                            <Button outline>
                                Change <img src={Arrow_Down} alt="Arrow_Down" />
                            </Button>
                            {showOptions && (
                                <div className={classes.changeOption}>
                                    <div className={classes.selectoption}>
                                        <img src={change_img} alt="change-img" />
                                        <p>Select from library</p>
                                    </div>
                                    <div className={classes.selectoption}>
                                        <img src={delete_img} alt="delete-img" />
                                        <p><span>Remove Image</span></p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <Button >
                             Edit <img src={edit_img} alt="edit_img" />
                        </Button>
                    </div>
                </div>
                <div className={classes.themeContainer}>
                    <h2>Manage Theme</h2>
                    <div className={`${classes.selectDropdown} mainBuiltinThemeStyle`}>
                        <FormControl sx={{ m: 1, minWidth: 50 }}>
                            <Select
                                labelId="demo-single-radio-label"
                                id="demo-single-radio"
                                displayEmpty
                                value={selectedTheme}
                                onChange={handleChange}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    
                                    if (!selected) {
                                        return <em>Select Theme</em>;
                                    }
                                    return selected;
                                }}
                            >
                                <MenuItem disabled value="" className="SettingselectBuiltinStylePlaceholder">
                                    <em>Select Theme</em>
                                </MenuItem>
                                {names.map((name) => (
                                    <MenuItem key={name} value={name} className="selectBuiltinStyleCutumization">
                                        <Radio checked={selectedTheme === name} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <h2>Primary Color</h2>
                    <div className={classes.selectColors}>
                        {colors.map((color, index) => (
                            <label key={index} className={classes.radioLabel}>
                                <input
                                    type="radio"
                                    className={classes.radio}
                                    value={color}
                                    checked={selectedColor === color}
                                    onChange={handleCheckboxChange}
                                />
                                <span style={{ backgroundColor: color }}>
                                    {selectedColor === color && (
                                        <img src={checked_img} alt="checked_img" />
                                        // <svg className={classes.checkmark} viewBox="0 0 24 24">
                                        //     <path fill="none" stroke="white" strokeWidth="2" d="M4 12l6 6L20 6" />
                                        // </svg>
                                    )}
                                </span>
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Customization;