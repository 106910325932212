
import React from "react";
import BasicModal from "../../components/shared/basicmodal";
import classes from "./reports.module.scss";
import img1 from "../../assets/images/sajiha/foodItem1.png";
import img2 from "../../assets/images/sajiha/foodItem2.png";
import img3 from "../../assets/images/sajiha/foodItem3.png";
import img4 from "../../assets/images/sajiha/foodItem4.png";
import img5 from "../../assets/images/sajiha/foodItem5.png";
import "./reportsBuiltin.scss";

const items = [
  {
    img: img1,
    alt: "Chicken Karrahi",
    name: "Chicken Karrahi",
    category: "Main Course Chicken Dishes",
    qty: "03"
  },
  {
    img: img2,
    alt: "Paratha Roll",
    name: "Paratha Roll",
    category: "Main Course Chicken Dishes",
    qty: "01"
  },
  {
    img: img3,
    alt: "Fresh Naan",
    name: "Fresh Naan",
    category: "Main Course Chicken Dishes",
    qty: "03"
  },
  {
    img: img4,
    alt: "Chicken BBQ",
    name: "Chicken BBQ",
    category: "Main Course Chicken Dishes",
    qty: "02"
  },
  {
    img: img5,
    alt: "Chicken Burger",
    name: "Chicken Burger",
    category: "Main Course Chicken Dishes",
    qty: "03"
  },
  {
    img: img1,
    alt: "Chicken Karrahi",
    name: "Chicken Karrahi",
    category: "Main Course Chicken Dishes",
    qty: "03"
  }
];

const ReportModal = ({ isOpen, onClose, children }) => {
  return (
    <BasicModal isOpen={isOpen} closeModal={onClose} customClass="reportmodal">
      <div className={classes.ReportModalHeader}>
        <h2>Report Detail</h2>
      </div>
      <div className={classes.ReportModalMainContainer}>
        <div className={classes.ReportModalContainer}>
          <div className={classes.transactioncontainer}>
            <h6>Transaction Detail:</h6>
            <div className={classes.transactionDetail}>
              <div className={classes.detailList}>
                <div className={classes.my}>
                  <p>Order Number</p>
                  <span>#AB02565</span>
                </div>
                <div className={classes.my}>
                  <p>Transaction Number:</p>
                  <span>2135424624</span>
                </div>
              </div>
              <div className={classes.detailListcenter}>
                <div className={classes.my}>
                  <p>Customer:</p>
                  <span>Michel Grades</span>
                </div>
                <div className={classes.my}>
                  <p>Total Items:</p>
                  <span>10 Items</span>
                </div>
              </div>
              <div className={classes.detailList}>
                <div className={classes.my}>
                  <p>By Date & Time:</p>
                  <span>1st Dec, 2023 (5:00 PM)</span>
                </div>
                <div className={classes.my}>
                  <p>Total sales:</p>
                  <span>$5,956.00</span>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.purchasedItems}>
            <h6>Purchased Items:</h6>
            <div className={classes.FoodList}>
              {items.map((item, index) => (
                <div className={classes.ItemsList} key={index}>
                  <div className={classes.item}>
                    <img
                      src={item.img}
                      alt={item.alt}
                      className={classes.itemImage}
                    />
                    <div className={classes.itemDetails}>
                      <h2>{item.name}</h2>
                      <div className={classes.category}>
                        <span>Category:</span> {item.category}
                      </div>
                      <h2 className={classes.qty}>Qty: {item.qty}</h2>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};

export default ReportModal;
