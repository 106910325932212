import React, { useState, useEffect } from "react";
import ReusableTable from "../../components/shared/resuabletable";
import Delete from "../../components/shared/deleteModal";
import classes from "./manageUser.module.scss";
import "./manageUserBuiltin.scss";
import BasicModal from "../../components/shared/basicmodal";
import UserForm from "./UserForm";
import ChangePass from "./changePass";
import TableFilter from "../../components/shared/tableFilter";

const ManageUser = () => {
  const [user, setUser] = useState([]);
  const [userDeep, setUserDeep] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [error, setError] = useState(null);
  const [addUser, setAddUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [editPass, setEditPass] = useState(false);
  const [password, setPassword] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    const handleData = async () => {
      try {
        const rest = await fetch(
          "https://667416f375872d0e0a950aed.mockapi.io/Users"
          // "http://localhost:8000/users"
        );
        const data = await rest.json();
        console.log(data, "data");
        setUser(data);
        setUserDeep(data);
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      }
    };
    handleData();
  }, []);
  const handleUserSubmit = (values) => {
    console.log(values);
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
  };

  useEffect(() => {
    if (searchTerm.length > 2) {
      const filteredCustomers = userDeep.filter((customer) =>
        customer?.name?.toLowerCase().includes(searchTerm)
      );
      setUser(filteredCustomers);
    } else {
      setUser(userDeep);
    }
  }, [searchTerm, userDeep]);

  const columns = [
    { label: "#", field: "index" },
    { label: "Created at", field: "Created_at" },
    { label: "Username", field: "Username" },
    { label: "Email", field: "Email" },
    { label: "Roles", field: "Roles" },
  ];

  const handleRoleClose = () => {
    setAddUser(false);
    setEditUser(false);
    setEditPass(false);
  };
  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
  };
  const handleDelete = (id) => {
    console.log("row-id:", id);
    setOpenDelete(true);
    setIsDelete(true);
    setDeleteId(id);
  };
  const handleConfirmDelete = () => {
    console.log("delete success", deleteId);
    setUser((prevUsers) => prevUsers.filter((user) => user.id !== deleteId));
    setIsDelete(false);
    setConfirmDelete(true);
  };
  const handleEdit = () => {
    setEditUser(true);
  };
  const addUserHandle = () => {
    setIsEditing(false);

    // console.log("clicked");
    setAddUser(true);
  };
  const handlePassword = () => {
    setPassword(true);
    setEditPass(true);
  };
  return (
    <div className={classes.mainManageUser}>
      <div className={classes.table_section}>
        <TableFilter
          heading="Users"
          tableLength={user.length}
          searchPlaceholder="Search in Customer"
          handleSearch={handleSearch}
          handleModalOpenLeft={addUserHandle}
          buttonTextLeft="Add New User"
          iconBtnLeft
          // handleModalOpenRight={addUserHandle}
          // buttonTextRight="Show Comments"
          // iconBtnRight
          datePicker
        />

        <div>
          <ReusableTable
            columns={columns}
            data={user}
            dataDeep={userDeep}
            handleDelete={handleDelete}
            searchTerm={searchTerm}
            pagination
            rowPerPage={11}
            handleEdit={handleEdit}
            handlePassword={handlePassword}
            actionButton
          />
        </div>
        {deleteId && (
          <Delete
            isOpen={openDelete}
            onClose={handleClose}
            handleConfirmDelete={handleConfirmDelete}
            isDelete={isDelete}
            confirmDelete={confirmDelete}
            name="User"
          />
        )}
        {addUser && (
          <BasicModal
            isOpen={addUser}
            closeModal={handleRoleClose}
            customWidth="994px"
          >
            <UserForm
              onSubmit={handleUserSubmit}
              textButton={"Add User"}
              headTitle={"Add New User"}
            />
          </BasicModal>
        )}
        {editPass && (
          <BasicModal
            isOpen={editPass}
            closeModal={handleRoleClose}
            customWidth="994px"
          >
            <ChangePass
              textButton={"Save Changes"}
              headTitle={"Change Password"}
            />
          </BasicModal>
        )}
        {editUser && (
          <BasicModal
            isOpen={editUser}
            closeModal={handleRoleClose}
            customWidth="994px"
          >
            <UserForm textButton={"Save Changes"} headTitle={"Edit User"} />
          </BasicModal>
        )}
      </div>
    </div>
  );
};

export default ManageUser;
